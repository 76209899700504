import { NavDropdown } from 'react-bootstrap'
import { NavLink, useMatch } from 'react-router-dom'
import { useAppContext } from 'src/hooks/useAppContext'
import { userrole_canAddInvoice, userrole_canEditAnyInvoice, userrole_canListInvoices, userrole_canListPayees, userrole_canMarkPaid, userrole_canViewCalendar, userrole_canViewRequests, userrole_isAdmin, userrole_isDev } from 'src/util/user_roles'
import { RoundUserDropdown } from './RoundUserDropdown'
import { SignInButton } from './SignInButton'


interface HeaderNavBarProps {
  isLoading: boolean
}

export function HeaderNavBar({ isLoading }: HeaderNavBarProps) {

  const { db, userDetails, perm, currentError, setDbError, _lang, setNavbarClickNewRequestCounter } = useAppContext()

  const clearDbError = () => {
    if (currentError && !currentError.isFatalError) { // fatal errors cannot be cleared
      setDbError('', null) // this causes a full page refresh though
    }
  }

  const collapseNavbar = () => {
    const menuToggle = document.getElementById('navbarsExample05')!

    if (!menuToggle.classList.contains('show')) {
      // causes strange animation if we try to collapse the menu when it's not showing
      return
    }

    // Code to create bsCollapse taken from https://stackoverflow.com/a/42401686/
    // `bootstrap` is imported from CDN in `index.html` so it's not defined on the React side.
    // Note that `bootstrap` is initially undefined during page load.
    // @ts-expect-error Bootstrap // eslint-disable-next-line no-undef
    const bsCollapse = bootstrap.Collapse.getOrCreateInstance(menuToggle, { toggle: false })
    bsCollapse.toggle()
  }

  const onNavbarLinkClick = () => {
    collapseNavbar()
    clearDbError()
  }



  // <CustomNavLink /> to match child paths also when deciding whether to make link active: https://stackoverflow.com/a/71054976/
  // Meaning of `end` parameter: https://stackoverflow.com/a/70263708/ (in comments)
  // let resolved = useResolvedPath(to);
  // let match = useMatch({ path: resolved.pathname, end: true });

  const isActiveInvoices = useMatch({ path: '/invoices/*' }) ? true : false
  const isActiveSuppliers = useMatch({ path: '/suppliers/*' }) ? true : false
  const isActiveClients = useMatch({ path: '/clients/*' }) ? true : false
  const isActiveDirectory = isActiveSuppliers || isActiveClients
  const isActiveRequests = useMatch({ path: '/requests/*' }) ? true : false
  const isActiveGuide = useMatch({ path: '/guide/*' }) ? true : false
  const isActiveExpenseAccounting = useMatch({ path: '/guide/expenseaccounting' }) ? true : false
  const isActiveAdminUsers = useMatch({ path: '/admin/users' }) ? true : false
  const isActiveCalendar = useMatch({ path: '/tourcalendar/' }) ? true : false
  const isActiveAdmin = isActiveAdminUsers
  const isActiveDev = false

  const isPaymentIssuer = userrole_canMarkPaid(userDetails?.roles)
  const isSeniorInputter = userrole_canEditAnyInvoice(userDetails?.roles)

  const permGuidingExpense = perm('guiding_expenses_view_own')
  const permGeneralExpense = perm('gen_expenses_view_own')
  const permCommute = perm('commute_page')

  if (currentError && currentError.isFatalError)
    // for fatal errors, hide navbar
    return null

  return (
    <>

      <nav className='navbar navbar-expand-md navbar-dark bg-dark' style={{ position: 'fixed', width: '100%', zIndex: 20, top: 0 }}>
        <div style={{ position: 'absolute', right: '0.625rem', top: '0.375rem' }}>
          {userDetails ? (

            <RoundUserDropdown />

          ) : (
            !isLoading && <SignInButton />
          )}
        </div>
        <div className='container-fluid' style={{ paddingRight: '5rem', alignItems: 'baseline' }}>
          <img src={import.meta.env.VITE_APP_LOGO_FILE} width={50} height={50} style={{ marginRight: '0.5rem', width: '3.125rem', height: '3.125rem', alignSelf: 'center' }} alt='Eighty Days Logo' />
          <NavLink className='navbar-brand' style={{ paddingTop: '0.45em' }} to='/'>{import.meta.env.VITE_APP_NAME}</NavLink>

          <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarsExample05' aria-controls='navbarsExample05' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='navbarsExample05'>
            <ul className='navbar-nav'>
              {userrole_canListInvoices(userDetails?.roles) && (
                <li className='nav-item'>
                  <NavLink className={`nav-link ${isActiveInvoices ? 'active' : ''}`} to='/invoices/' onClick={onNavbarLinkClick}>{_lang('Invoices', '請求書')}</NavLink>
                </li>
              )}
              {userrole_canViewRequests(userDetails?.roles) ? (
                <li className='nav-item dropdown'>
                  <NavDropdown title={_lang('Requests', '顧客依頼')} className={` ${isActiveRequests ? 'active' : ''}`}>
                    <NavDropdown.Item as={NavLink} end={true} to='/requests/' onClick={onNavbarLinkClick}>{_lang('Request List', '顧客依頼リスト')}</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} end={true} to='/requests/search' onClick={onNavbarLinkClick}>{_lang('Search Request List', '顧客以来を検索')}</NavDropdown.Item>
                    {userrole_canAddInvoice(userDetails?.roles) && (
                      <>
                        <NavDropdown.Item as={NavLink} end={true} to='/requests/add' onClick={() => {
                          onNavbarLinkClick()
                          setNavbarClickNewRequestCounter((prev) => prev + 1)
                        }}>{_lang('Create New Request', '新規顧客依頼')}</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={NavLink} end={true} to='/quotations' onClick={onNavbarLinkClick}>Quotation sheets</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={NavLink} end={true} to='/tourcalendar/' onClick={onNavbarLinkClick}>Tour Calendar</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} end={true} to='/explore-bookings' onClick={onNavbarLinkClick}>Explore bookings</NavDropdown.Item>
                      </>
                    )}
                  </NavDropdown>
                </li>
              ) : userrole_canViewCalendar(userDetails?.roles) ? (
                <li className='nav-item'>
                  <NavLink className={`nav-link ${isActiveCalendar ? 'active' : ''}`} to='/tourcalendar/' onClick={onNavbarLinkClick}>Calendar</NavLink>
                </li>
              ) : null}
              {userDetails && (permGuidingExpense || permGeneralExpense || permCommute) && (
                <li className='nav-item dropdown'>
                  <NavDropdown title={_lang('Expenses', '費用')} className={` ${isActiveGuide ? 'active' : ''}`}>
                    {permGuidingExpense && (
                      <>
                        <NavDropdown.Header>{_lang('Guiding expenses', 'ガイド費用')}</NavDropdown.Header>
                        <NavDropdown.Item as={NavLink} end={true} to='/guide/expenses/' onClick={onNavbarLinkClick}>{_lang('Expense sheets', '費用シート')}</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} end={true} to='/guide/expenseaccounting/' onClick={onNavbarLinkClick}>{_lang('Expense accounting', '費用会計')}</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} end={true} to='/guide/expenses/rules/' onClick={onNavbarLinkClick}>{_lang('Guiding expense rules', 'ガイド費用のルール')}</NavDropdown.Item>
                      </>
                    )}
                    {(permGuidingExpense && (permGeneralExpense || permCommute)) && (
                      <NavDropdown.Divider />
                    )}
                    {permGeneralExpense && (
                      <>
                        <NavDropdown.Header>{_lang('General expenses', '一般経費')}</NavDropdown.Header>
                        <NavDropdown.Item as={NavLink} end={true} to='/general-expenses/' onClick={onNavbarLinkClick}>{_lang('General expenses', '一般経費')}</NavDropdown.Item>
                      </>
                    )}
                    {(permGeneralExpense && permCommute) && (
                      <NavDropdown.Divider />
                    )}
                    {permCommute && (
                      <>
                        <NavDropdown.Header>{_lang('Commuting expenses', '通勤交通費')}</NavDropdown.Header>
                        <NavDropdown.Item as={NavLink} end={true} to='/commute/' onClick={onNavbarLinkClick}>{_lang('Monthly sheet', '月次入力')}</NavDropdown.Item>
                        {isSeniorInputter && (
                          <NavDropdown.Item as={NavLink} end={true} to='/commute/allemployees' onClick={onNavbarLinkClick}>{isPaymentIssuer ? 'Summary all employees' : 'Summary all direct reports'}</NavDropdown.Item>
                        )}
                      </>
                    )}
                  </NavDropdown>
                </li>
              )}
              {userrole_canListPayees(userDetails?.roles) && (
                true ? (
                  <li className='nav-item dropdown'>
                    <NavDropdown title={_lang('Directory', '取引先リスト')} className={` ${isActiveDirectory ? 'active' : ''}`}>
                      <NavDropdown.Item as={NavLink} end={true} to='/suppliers/' onClick={onNavbarLinkClick}>{_lang('Suppliers', '支払先リスト')}</NavDropdown.Item>
                      <NavDropdown.Item as={NavLink} end={true} to='/clients/edit/' onClick={onNavbarLinkClick}>{_lang('Clients', '顧客')}</NavDropdown.Item>
                      {userrole_isAdmin(userDetails?.roles) && (
                        <NavDropdown.Item as={NavLink} end={true} to='/clients/list/' onClick={onNavbarLinkClick}>{_lang('Client full list', '顧客リスト')}</NavDropdown.Item>
                      )}
                    </NavDropdown>
                  </li>
                ) : (
                  <li className='nav-item'>
                    <NavLink className={`nav-link ${isActiveSuppliers ? 'active' : ''}`} to='/suppliers/' onClick={onNavbarLinkClick}>{_lang('Suppliers', '支払先リスト')}</NavLink>
                  </li>
                )
              )}
              {/* {userrole_expensesTravelDesigner(userDetails?.roles) && (
                <li className="nav-item">
                  <NavLink className={`nav-link ${isActiveRequests ? 'active' : ''}`} to='/commute/' onClick={clearDbError}>{_lang('Commuting', '通勤')}</NavLink>
                </li>
              )} */}
              {userrole_isAdmin(userDetails?.roles) && (
                <li className='nav-item dropdown'>
                  <NavDropdown title={_lang('Admin', '管理者')} className={` ${isActiveAdmin ? 'active' : ''}`}>
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/users' onClick={onNavbarLinkClick}>{_lang('Users', 'ユーザーリスト')}</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/emergencyphones' onClick={onNavbarLinkClick}>Emergency phones</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} end={true} to='/agency-monthly-stats' onClick={onNavbarLinkClick}>{_lang('Agency Monthly Stats', '月次の旅行代理店ごとの統計')}</NavDropdown.Item>
                  </NavDropdown>
                </li>
              )}
              {userrole_isDev(userDetails?.roles) && (
                <li className='nav-item dropdown'>
                  <NavDropdown title='Dev' className={` ${isActiveDev ? 'active' : ''}`}>
                    <NavDropdown.ItemText className='nav-dropdown-item-text'>Adhoc tools</NavDropdown.ItemText>
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/tools' onClick={onNavbarLinkClick}>Admin Tools</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/rulestesting' onClick={onNavbarLinkClick}>Rules testing</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/testpage' onClick={onNavbarLinkClick}>Test page</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.ItemText className='nav-dropdown-item-text'>Migration tools</NavDropdown.ItemText>
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/kintoneimport' onClick={onNavbarLinkClick}>Kintone Request Importer</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/salesinfoimport' onClick={onNavbarLinkClick}>Kintone Sales Info Importer</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/importtourcalendar' onClick={onNavbarLinkClick}>Import Tour Calendar</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/fixagencynames' onClick={onNavbarLinkClick}>Fix agency names</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} end={true} to='/admin/viewlogs' onClick={onNavbarLinkClick}>View Logs</NavDropdown.Item>
                  </NavDropdown>
                </li>
              )}
            </ul>


            {/* <form role="search">
              <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
            </form> */}

          </div>

        </div>

      </nav>

      {/* placeholder to occupy same space as position:fixed navbar at top of page */}
      <div style={{ height: '4.125rem' }}></div>


      {/* next, there will be the user messages (annoucements, version upgrade message) rendered by MessageBar */}

    </>


  )
}
