import { QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { BookingUpdateType, BookingUpdateWithTourMapType, BookingsOneTourType } from 'src/types/objectTypes';
import { TourRequestType } from 'src/types/types_tourrequest';
import { dateFormatJpShort, dateisoFormatMMMYYYY } from 'src/util/dateformattools';
import { getJstMidnightBasedOnJstTime, iso_from_jst0 } from 'src/util/datetools';
import { convertExploreBookingsDates, convertTourRequestDates } from 'src/util/util_firestoredates';
import { log_db_read } from 'src/util/util_log';
import { stringCompare } from 'src/util/util_misc';
import { getTourrequestColorsFromExploreCode } from '../TourCalendar/util_tourcalendar_colors';
import './explorebookings.css';



export function ExploreBookings() {

  const { db, setDbError, storage, userDetails, _lang } = useAppContext()

  const [selectedEmailDateIndex, setSelectedEmailDateIndex] = useState<number>(0)

  const [bookings, setBookings] = useState<Map<string, BookingUpdateWithTourMapType>>() // email_date => bookingupdate
  const [emailDates, setEmailDates] = useState<string[]>()
  useEffect(() => {

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const map = new Map<string, BookingUpdateWithTourMapType>()
      const emailDates: string[] = []
      for (const docu of snapshot.docs) {
        const bookingsUpdate = { ...docu.data(), id: docu.id } as BookingUpdateType
        convertExploreBookingsDates(bookingsUpdate)

        // remove the year from the end of tour names
        const listBookings = bookingsUpdate.listBookings
        for (const b of listBookings) {
          const match = b.tour_name.match(/^(.+)_(\d{4})$/)
          if (!match)
            throw new Error(`tour name does not end with year: ${b.tour_name}`)
          const sYear = match[2]
          b.tour_name = match[1]
          if (b.tour_date_iso.slice(0, 4) !== sYear)
            throw new Error(`tour date year [${b.tour_date_iso}] does not match tour name year [${b.tour_name}]`)
        }

        // create map by tour name
        const tourMap = new Map<string, Map<string, BookingsOneTourType>>() // tourname => date => booking
        for (const booking of listBookings) {
          let mapThisTour = tourMap.get(booking.tour_name)
          if (!mapThisTour) {
            mapThisTour = new Map<string, BookingsOneTourType>()
            tourMap.set(booking.tour_name, mapThisTour)
          }
          if (mapThisTour.has(booking.tour_date_iso))
            throw new Error(`duplicate tour name and date: ${booking.tour_name} ${booking.tour_date_iso}`)
          mapThisTour.set(booking.tour_date_iso, booking)
        }

        const bookingsWithMap: BookingUpdateWithTourMapType = { ...bookingsUpdate, tourMap }

        const dateEmail = bookingsUpdate.date_explore_sent
        const dateEmail0 = getJstMidnightBasedOnJstTime(dateEmail)
        const sDate = iso_from_jst0(dateEmail0)

        // check for multiple emails on same day: only keep most recent
        const existing = map.get(sDate)
        if (!existing || existing.date_explore_sent < bookingsUpdate.date_explore_sent) {
          map.set(sDate, bookingsWithMap)
          if (!existing) {
            emailDates.push(sDate)
          }
        }

      }
      // sort with most recent first
      emailDates.sort().reverse()
      setBookings(map)
      setEmailDates(emailDates)
    }

    log_db_read({ db, userDetails, logkey: 'db_read.list_explorebookings', desc: 'List explorebookings' })

    const q = query(collection(db, 'explorebookings'), where('_isDeleted', '==', false))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting explorebookings', err));
    return unsubscribe
  }, [db, setDbError, userDetails])


  const [tourrequests, setTourrequests] = useState<TourRequestType[]>()
  useEffect(() => {
    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: TourRequestType[] = []
      for (const docu of snapshot.docs) {
        const tourrequest = { ...docu.data(), id: docu.id } as TourRequestType
        convertTourRequestDates(tourrequest)
        list.push(tourrequest)
      }
      setTourrequests(list)
    }

    log_db_read({ db, userDetails, logkey: 'db_read.list_tourrequests.where_isexploreseries', desc: 'List tourrequests' })

    const q = query(collection(db, 'tourrequests'), where('_isDeleted', '==', false), where('isExploreSeries', '==', true))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting tourrequests', err));
    return unsubscribe
  }, [db, setDbError, userDetails])


  const [shownPopup, setShownPopup] = useState<string | null>(null)

  const refTableContainer = useRef<HTMLDivElement>(null)


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['explore booking updates', bookings && emailDates],
    ['tour requests', tourrequests],
  ])
  if (!bookings || !emailDates || !tourrequests)
    return loadingSpinner


  const selectedEmailDate = emailDates[selectedEmailDateIndex]
  const current = bookings.get(selectedEmailDate)!

  const prevEmailDate = emailDates.at(selectedEmailDateIndex + 1)
  const prevBookingUpdate = prevEmailDate && bookings.get(prevEmailDate)

  const date0 = getJstMidnightBasedOnJstTime(current.date_explore_sent)
  const sDate = dateFormatJpShort(date0)

  const listBookings = current.listBookings

  const listTours = [...new Set(listBookings.map((b) => {
    // const match = b.tour_name.match(/^(.+)_\d{4}$/)
    // if (!match)
    //   throw new Error(`tour name does not end with year: ${b.tour_name}`)
    // return match[1]
    return b.tour_name
  })).keys()].sort(stringCompare)

  // console.log('listTours', listTours)

  const listMonths = [...new Set(listBookings.map((b) => b.tour_date_iso.slice(0, 7)))].sort()
  const minMonth = listMonths.at(0)!
  const maxMonth = listMonths.at(-1)!
  const fullListMonths = []
  let year = Number(minMonth.slice(0, 4))
  let month = Number(minMonth.slice(5, 7))
  while (true) {
    fullListMonths.push(`${year}-${month.toString().padStart(2, '0')}`)
    if (year === Number(maxMonth.slice(0, 4)) && month === Number(maxMonth.slice(5, 7)))
      break
    month++
    if (month > 12) {
      month = 1
      year++
    }
  }



  // fixed header row:
  // https://stackoverflow.com/a/32092987/
  // https://jsfiddle.net/dPixie/byB9d/3/light/
  // another way is to use css position:sticky, but the UI is more awkward (header row floating on top of the page)

  // const FIRST_COL_WIDTH_EM = 7
  // const FIRST_COL_WIDTH = `${FIRST_COL_WIDTH_EM}em`
  const COL_WIDTH_EM = 18
  const COL_WIDTH = `${COL_WIDTH_EM}em`
  const TOTAL_WIDTH_EM = COL_WIDTH_EM * listTours.length
  const TOTAL_WIDTH = `${TOTAL_WIDTH_EM}em`
  const HEADER_HEIGHT_EM = 5
  const HEADER_HEIGHT = `${HEADER_HEIGHT_EM}em`

  return (
    <div className='container-fluid'>
      <Helmet><title>Explore bookings</title></Helmet>

      <h3 className='my-4'>Explore bookings update - {sDate}</h3>

      <div style={{ width: '12em' }}>
        <Form.Select id='dropdown-select-email-date' className='mb-3' value={selectedEmailDateIndex} onChange={(e) => {
          setSelectedEmailDateIndex(Number(e.currentTarget.value))
        }
        }>
          {emailDates.map((date, index) => {
            return (
              <option key={date} value={index}>{date}</option>
            )
          })}
        </Form.Select>
      </div>

      <div className='mb-3'>
        <div><b>10</b>: number of bookings</div>
        <div><span className='guaranteed'>(G)</span>: guaranteed</div>
        <div><span className='singlerooms'>[S=1]</span>: single rooms</div>
      </div>

      <section style={{
        position: 'relative',
        width: TOTAL_WIDTH,
        paddingTop: HEADER_HEIGHT,
        backgroundColor: 'white',
      }}>
        <div ref={refTableContainer} style={{
          overflowY: 'auto',
          height: `calc(100vh - 15em - ${HEADER_HEIGHT})`,
          width: TOTAL_WIDTH,
          borderTop: '2px solid black',
        }}>

          <table className='table' style={{
            width: TOTAL_WIDTH,
          }}>
            <colgroup>
              {/* <col style={{ width: FIRST_COL_WIDTH }} /> */}
              {listTours.map((tourName) => (
                <col key={tourName} style={{ width: COL_WIDTH }} />
              ))}
            </colgroup>
            <thead>
              <tr>
                {/* <th style={{
                  width: FIRST_COL_WIDTH,
                  padding: 0,
                }}></th> */}
                {listTours.map((tourName) => {
                  const match = tourName.match(/^(.+)-([A-Z]{2,3})$/)
                  const [title, code] = match ? [match[1], match[2]] : [tourName, '']

                  const colors = getTourrequestColorsFromExploreCode(code) ?? { mainBg: 'white', mainText: 'black' }

                  return (
                    <th key={tourName} style={{
                      padding: 0,
                      backgroundColor: colors.mainBg,
                    }}>
                      <div style={{
                        position: 'absolute',
                        width: COL_WIDTH,
                        top: 0,
                        height: '3.2em',
                        padding: '0.25em 0.5em',
                        borderLeft: '1px solid silver',
                        backgroundColor: colors.mainBg,
                        color: colors.mainText,
                      }}>
                        <span className='tw-border tw-border-solid tw-border-slate-500 tw-p-1 tw-rounded tw-ml-2 tw-bg-slate-500 tw-text-white' style={{ float: 'right' }}>{code}</span>
                        {title}
                      </div>
                    </th>
                  )
                })}
              </tr>
              <tr>
                {/* <th style={{
                  padding: 0,
                }}></th> */}
                {listTours.map((tourName) => {
                  let total = 0
                  let delta = 0
                  const thisTourMap = current.tourMap.get(tourName) ?? new Map() // date => booking
                  for (const [sDate, booking] of thisTourMap) {
                    total += Number(booking.clients_booked)
                    if (prevBookingUpdate) {
                      const prev_booking = prevBookingUpdate.tourMap.get(tourName)?.get(sDate)
                      const prev_num = Number(prev_booking?.clients_booked) || 0
                      const thisDelta = Number(booking.clients_booked) - prev_num
                      delta += thisDelta
                    }
                  }

                  let change = ''
                  if (delta > 0)
                    change = `(+${delta})`
                  else if (delta < 0)
                    change = `(${delta})`

                  return (
                    <th key={tourName} style={{
                      height: 0,
                      padding: 0,
                      fontWeight: 'normal',
                    }}>
                      <div style={{
                        position: 'absolute',
                        width: COL_WIDTH,
                        top: '3.2em',
                        padding: '0.25em 0.5em',
                        borderLeft: '1px solid silver',
                      }}>
                        {total} {change}
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {fullListMonths.map((month, iMonth) => {
                const listBookingsThisMonth = listBookings.filter((b) => b.tour_date_iso.slice(0, 7) === month)
                return (
                  <tr key={month} className={(iMonth === 0 || month.endsWith('-01')) ? 'separator' : undefined}>
                    {/* <th className='text-nowrap'>{month}</th> */}
                    {listTours.map((tourName) => {
                      const listBookingsThisMonthThisTour = listBookingsThisMonth.filter((b) => b.tour_name === tourName)
                      const match_code = tourName.match(/-([A-Z]{2,3})$/)
                      const exploreCode = match_code?.[1]
                      return (
                        <td key={tourName} style={{
                          borderLeft: '1px solid silver',
                          padding: 0,
                        }}>
                          <div className='tw-bg-slate-200 tw-text-center tw-text-sm' style={{
                            borderTop: '1px solid black',
                          }}>
                            {dateisoFormatMMMYYYY(`${month}-01`)}
                          </div>
                          <div className='tw-p-2'>
                            {listBookingsThisMonthThisTour.map((booking, index) => {
                              let change = ''
                              let change_single = ''
                              let guarantee_label = booking.guarantee
                              if (prevBookingUpdate) {
                                const prev_booking = prevBookingUpdate.tourMap.get(tourName)?.get(booking.tour_date_iso)
                                const prev_num = Number(prev_booking?.clients_booked) || 0
                                const delta = Number(booking.clients_booked) - prev_num
                                if (delta === 0)
                                  change = ''
                                else if (delta > 0)
                                  change = `(+${delta})`
                                else
                                  change = `(${delta})`

                                const prev_single = Number(prev_booking?.single_rooms) || 0
                                const delta_single = Number(booking.single_rooms) - prev_single
                                if (delta_single === 0)
                                  change_single = ''
                                else if (delta_single > 0)
                                  change_single = `(+${delta_single})`
                                else
                                  change_single = `(${delta_single})`

                                const prev_g = prev_booking?.guarantee || ''
                                if (prev_g !== booking.guarantee) {
                                  if (prev_g === '' && booking.guarantee === '(G)') {
                                    // changed from non-guaranteed to guaranteed
                                    guarantee_label = '(+G)'
                                  } else {
                                    // in practice this doesn't seem to happen
                                    console.log(`GUARANTEE STATUS CHANGED FROM [${prev_g}] TO [${booking.guarantee}] month=${month} tour=${tourName} date=${booking.tour_date_iso}`)
                                  }
                                }
                              }

                              const tourrequest = tourrequests.find((t) => exploreCode && t.exploreSeriesCode && t.exploreSeriesCode === exploreCode && t.dateisoTourStart === booking.tour_date_iso)

                              const dateLabel = booking.tour_date_iso.replaceAll('-', '/')

                              let tourNumber: string
                              if (tourrequest) {
                                const match1 = tourrequest.requestCode.match(/^E[0-9][1-9XYZ].-[A-Z]+([0-9]{1,3}[A-Z]?)$/)
                                if (match1) {
                                  tourNumber = match1[1]
                                } else {
                                  const match2 = tourrequest.requestCode.match(/^[A-Za-z ]+ ([0-9]{1,3}[A-Z]?) \(202[34]\)$/)
                                  if (match2) {
                                    tourNumber = match2[1]
                                  } else {
                                    tourNumber = ''
                                  }
                                }
                              } else {
                                tourNumber = ''
                              }

                              return (
                                <div key={index}>
                                  <span className='tw-inline-block tw-w-28'>
                                    {tourrequest ? (
                                      <RequestCodeLinkToAggregator
                                        requestCode={tourrequest.requestCode}
                                        linkId={tourrequest.id}
                                        shownPopup={shownPopup}
                                        setShownPopup={setShownPopup}
                                        alternateLabel={dateLabel}
                                      />
                                    ) : (
                                      dateLabel
                                    )}
                                  </span>
                                  <span className='tw-rounded-sm tw-bg-slate-300 tw-px-1 tw-inline-block tw-leading-tight tw-w-10 tw-text-center tw-mr-2'>{tourNumber}</span>
                                  {' '}
                                  <span style={booking.clients_booked === '0' ? { color: 'silver' } : { fontWeight: 'bold' }}>{booking.clients_booked} {change}</span>
                                  {' '}
                                  {/* {b.guarantee && <i style={{ fontSize: '1.25em' }} className='bi bi-patch-check'></i>} */}
                                  <span className='guaranteed'>{guarantee_label}</span>
                                  {booking.single_rooms && <span className='singlerooms'>[S={booking.single_rooms}{change_single}]</span>}
                                </div>
                              )
                            })}
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>

        </div>
      </section>



    </div>
  )
}
