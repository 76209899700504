import { httpsCallable } from 'firebase/functions'
import { ReactNode, useState } from 'react'
import { Form } from 'react-bootstrap'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { FilterButton } from 'src/components/Buttons/FilterButton'
import { useSetOneSearchParam } from 'src/components/ColumnFilters/useSetOneSearchParam'
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator'
import { useAutosaveDocumentSingle } from 'src/hooks/autosave/util_autosave'
import { useAppContext } from 'src/hooks/useAppContext'
import { FreeeInputCell } from 'src/pages/Invoices/FreeeInputCell'
import { TeamName } from 'src/pages/Requests/TeamName'
import { TourRequestHitType, TourRequestType } from 'src/types/types_tourrequest'
import { dateFormatJp, dateFormatJpShortWithTime, dateisoFormatJpShort } from 'src/util/dateformattools'
import { countDaysIso, isMidnightJST } from 'src/util/datetools'
import { userrole_canAddInvoice, userrole_canEditFreee, userrole_isAdmin, userrole_isDev } from 'src/util/user_roles'
import { formatNum } from 'src/util/util_formatnum'
import { arraySum } from 'src/util/util_misc'
import { getUserSimpleUid } from 'src/util/util_users'
import { StatusKeyType } from '../../PageTourRequests'
import { addMetadataModifiedTourRequest } from '../../RequestsCrud/util_db_tourrequests'
import { RequestListColumnFilterAppliedData } from '../../types_tourrequest_state'
import { getTwBorder } from '../../util_tourrequests'
import { PillListMutuallyExclusiveType, PillListType } from '../RequestStatusPill/RequestStatusOnePill'
import { RequestStatusPillCurrent } from '../RequestStatusPill/RequestStatusPillCurrent'
import { RequestStatusPillEditor } from '../RequestStatusPill/RequestStatusPillEditor'
import { getSelectedPill, saveTourStatusToDb_getUpdateObj } from '../RequestStatusPill/util_tourrequest_pill'
import { HighlightField } from './HighlightField'
import { RequestExpenseMiniTable } from './RequestExpenseMiniTable'
import { RequestStatusIcon } from './RequestStatusIcon'
import { SalesInfo1td } from './SalesInfo1td'


const duplicatesRequestNumbers = [
  540,
  283,
  225,
]


interface RequestListTableRowProps {
  tourrequest: TourRequestHitType
  statusKey: StatusKeyType
  showAllColumns: boolean
  showSalesInfo1: boolean
  showPayments: boolean
  showExpensesMiniTable: boolean
  expandPaymentsTable: boolean
  setExpandPaymentsTable: (value: boolean) => void
  indexFirstItem: number
  index: number
  travellersShownReqIdList: string[]
  setTravellersShownReqIdList: (func: (oldlist: string[]) => string[]) => void
  hideEditButton?: boolean
  rowClickCallback?: () => void
  shownRequestAggregatorPopup?: string | null
  setShownRequestAggregatorPopup?: (value: string | null) => void
  shownTourStatusPopup?: string | null
  setShownTourStatusPopup?: (value: string | null) => void // only passed on main request list page; not passed on search results or request code selector pages
  columnFilterAppliedData?: RequestListColumnFilterAppliedData | null // only passed if data can be filtered. not passed from <RequestCodeSelector/> or <TourRequestSearch/>
  editedCell?: string | null
  setEditedCell?: (cellid: string | null) => void
  freeeUnlocked?: boolean
}

export function RequestListTableRow({
  tourrequest,
  statusKey,
  showAllColumns,
  showSalesInfo1,
  showPayments,
  showExpensesMiniTable,
  expandPaymentsTable,
  setExpandPaymentsTable,
  indexFirstItem,
  index,
  travellersShownReqIdList,
  setTravellersShownReqIdList,
  hideEditButton,
  rowClickCallback,
  shownRequestAggregatorPopup,
  setShownRequestAggregatorPopup,
  shownTourStatusPopup,
  setShownTourStatusPopup,
  columnFilterAppliedData,
  editedCell,
  setEditedCell,
  freeeUnlocked,
}: RequestListTableRowProps) {

  const { db, setDbError, userDetails, cloudFunctions } = useAppContext()

  const [selectedPill, setSelectedPill] = useState<PillListMutuallyExclusiveType>()

  const tourrequests_setFreeeOnRequestPayment = httpsCallable(cloudFunctions, 'tourrequests_setFreeeOnRequestPayment');


  const showTravellers = travellersShownReqIdList.includes(tourrequest.id)
  const si = tourrequest.salesInformation
  const si2 = tourrequest.salesInfo2
  const payments = tourrequest.salesInfo2?.payments //?.filter(payment => payment.isPaid)
  const hasPayments = payments && payments.length > 0

  // two cases:
  //  * this request has 1 or more quotations. in this case, paymentsOk if total received = total quoted
  //  * otherwise, paymentsOk if:
  //    - at least one payment
  //    - all payments are received
  //    - either one "full amount" or one "deposit" and one "balance" payment, with optionally some "Additional"
  let paymentsOk = false
  if (hasPayments) {
    const unpaid = payments.filter((payment) => !payment.isPaid)
    if (unpaid.length === 0) {
      const totalQuoted = arraySum((tourrequest.salesInfo2.quotations || []).filter((q) => !q.superseded).map((q) => q.quotedAmount ?? 0))
      if (totalQuoted) {
        // paymentsOk if total quoted = total paid
        const totalPaid = arraySum(payments.filter((p) => p.isPaid).map((p) => p.paymentAmount ?? 0))
        if (totalPaid === totalQuoted)
          paymentsOk = true
      } else {
        // no quotation info: paymentsOk if payment types indicate it was all paid
        if (payments.length >= 1
          && payments[0].paymentType === 'Full amount'
          && payments.slice(1).filter((p) => p.paymentType !== 'Additional').length === 0)
          paymentsOk = true
        if (payments.length >= 2
          && payments[0].paymentType === 'Deposit'
          && payments[1].paymentType === 'Balance'
          && payments.slice(2).filter((p) => p.paymentType !== 'Additional').length === 0)
          paymentsOk = true
      }
    }
  }



  const isTrash = tourrequest.status === 'TRASH'

  const canEditRow = userrole_isAdmin(userDetails.roles)
    || (userrole_canAddInvoice(userDetails.roles)
      // && tourrequest.userCreatedUid === userDetails.id
    )

  const strTravelDesigners: ReactNode[] = []
  let lastModifiedDesignerFound = false
  tourrequest.usersDesigners.forEach((user, i) => {
    if (i > 0)
      strTravelDesigners.push(', ')
    // TODO: add back highlighting
    if (user.uid === tourrequest.userModifiedUid) {
      if (tourrequest.usersDesigners.length > 1) {
        strTravelDesigners.push(<u key='user_modified' title={`Last modified by: ${user.name}`}>{user.name}</u>)
      } else {
        strTravelDesigners.push(user.name)
      }
      lastModifiedDesignerFound = true
    } else {
      strTravelDesigners.push(user.name)
    }
  })
  if (!lastModifiedDesignerFound) {
    strTravelDesigners.push(' (')
    strTravelDesigners.push(<u key='user_modified' title={`Last modified by: ${tourrequest.userModifiedName}`}>{tourrequest.userModifiedName}</u>)
    strTravelDesigners.push(')')
  }

  const autosaveNewStep = useAutosaveDocumentSingle('tourrequests', tourrequest, addMetadataModifiedTourRequest)

  const filtersAvailable = columnFilterAppliedData ? true : false

  // React forces us to call useSetOneSearchParam hook even when filtersAvailable is false.
  const { setOneSearchParam: setOneSearchParam_ } = useSetOneSearchParam('tourrequests', filtersAvailable)
  const setOneSearchParam = filtersAvailable ? setOneSearchParam_ : undefined


  return (
    // key={tourrequest.id}
    <tr onClick={rowClickCallback} className={`${rowClickCallback ? 'rowClickable' : null} ${isTrash ? 'tourrequest-in-trash' : ''}`}>
      <td title={userrole_isDev(userDetails.roles) ? tourrequest.id : ''} className='tw-relative !tw-ps-6'>
        {statusKey && statusKey !== 'all' && (
          <div className={`
            tw-absolute tw-top-0 tw-left-0 
            tw-w-5  tw-h-full 
            tw-border-l-4 tw-border-r-0 tw-border-y-0
            tw-border-solid ${getTwBorder(statusKey)}`}>
          </div>
        )}
        <div className='tw-absolute tw-text-slate-400 tw-top-[0px] tw-left-[5px] tw-text-sm'>
          {1 + indexFirstItem + index}
        </div>
        {(!hideEditButton && !isTrash) && (
          <ButtonTW variant='blue_outline' to={`/requests/edit/${tourrequest.id}`}>Open</ButtonTW>
        )}
      </td>
      {showAllColumns && (
        <>
          <td>{1 + index}</td>
          <td>{tourrequest.id}</td>
          <td>{tourrequest.kintoneRecordNumber}</td>
        </>
      )}
      <td>
        <TeamName name={tourrequest.eightyDaysDepartment} />
        {' '}
        {/* // if this filter is not active, we set filterValue as undefined instead of null
            // to avoid filterValue being equal to currentValue wnen currentValue is also null */}
        {columnFilterAppliedData && setOneSearchParam && (
          <FilterButton
            filterActive={columnFilterAppliedData.appliedFilterTeamCategory?.has(tourrequest.eightyDaysDepartment) ?? false}
            setFilterValue={(filterValue) => setOneSearchParam('teamCategory', filterValue)}
            currentValue={tourrequest.eightyDaysDepartment}
            title='Filter by Team/Category'
          />
        )}
      </td>
      <td><HighlightField tourrequest={tourrequest} fieldname='customerType' /></td>
      {showAllColumns && (
        <>
          <td className={duplicatesRequestNumbers.includes(tourrequest.requestNumber ?? 0) ? 'duplicate-number' : ''}><HighlightField tourrequest={tourrequest} fieldname='requestNumber' /></td>
        </>
      )}
      <td>
        {setShownRequestAggregatorPopup ? (
          <RequestCodeLinkToAggregator
            requestCode={tourrequest.requestCode}
            linkId={tourrequest.id}
            shownPopup={shownRequestAggregatorPopup}
            setShownPopup={setShownRequestAggregatorPopup}
            highlightResult={tourrequest._highlightResult?.requestCode?.value}
          />
        ) : (
          <HighlightField tourrequest={tourrequest} fieldname='requestCode' />
        )}
      </td>
      <td>
        <HighlightField tourrequest={tourrequest} fieldname='agencyOrPlatform' />
        {' '}
        {/* // if this filter is not active, we set filterValue as undefined instead of null
            // to avoid filterValue being equal to currentValue wnen currentValue is also null */}
        {columnFilterAppliedData && setOneSearchParam && (
          <FilterButton
            filterActive={columnFilterAppliedData.appliedFilterAgency?.has(tourrequest.agencyOrPlatformId) ?? false}
            setFilterValue={(filterValue) => setOneSearchParam('agencyId', filterValue)}
            currentValue={tourrequest.agencyOrPlatformId}
            title='Filter by Agency/Platform'
          />
        )}
      </td>
      <td><HighlightField tourrequest={tourrequest} fieldname='travellerName' /></td>
      <td>
        <div style={{ minWidth: '10em' }}>
          <HighlightField tourrequest={tourrequest} fieldname='country' />
          {' '}
          {/* // if this filter is not active, we set filterValue as undefined instead of null
              // to avoid filterValue being equal to currentValue wnen currentValue is also null */}
          {columnFilterAppliedData && setOneSearchParam && (
            <FilterButton
              filterActive={columnFilterAppliedData.appliedFilterCountry?.has(tourrequest.country) ?? false}
              setFilterValue={(filterValue) => setOneSearchParam('country', filterValue)}
              currentValue={tourrequest.country}
              title='Filter by Country'
            />
          )}
        </div>
      </td>
      {showAllColumns && (
        <>
          <td><HighlightField tourrequest={tourrequest} fieldname='stateOrRegion' /></td>
        </>
      )}

      {/* sales info */}
      {showSalesInfo1 && <SalesInfo1td tourrequest={tourrequest} />}

      <td>{tourrequest.dateisoTourStart && dateisoFormatJpShort(tourrequest.dateisoTourStart)}</td>
      <td>{tourrequest.dateisoTourEnd && dateisoFormatJpShort(tourrequest.dateisoTourEnd)}</td>
      {showAllColumns && (
        <td>
          {tourrequest.dateisoTourStart && tourrequest.dateisoTourEnd && (1 + countDaysIso(tourrequest.dateisoTourStart, tourrequest.dateisoTourEnd))}
        </td>
      )}
      <td>
        <div className='tw-flex'>
          <div className={shownTourStatusPopup === tourrequest.id ? 'tw-opacity-40' : ''}>
            <RequestStatusPillCurrent
              tourrequest={tourrequest}
              clickHandler={
                (userrole_canAddInvoice(userDetails.roles) && setShownTourStatusPopup) ? (
                  () => {
                    setSelectedPill(getSelectedPill(tourrequest))
                    setShownTourStatusPopup(tourrequest.id)
                  }
                ) : undefined // undefined means the status will not be clickable by user
              }
              vertical={true}
            />
          </div>
          <div>
            {setShownTourStatusPopup && shownTourStatusPopup === tourrequest.id && (
              <div className='tw-absolute tw-rounded tw-border tw-border-solid tw-border-slate-400 tw-p-1 tw-bg-white tw-ml-4 tw-w-36'>
                <div className='tw-flex tw-flex-col tw-gap-4'>
                  <RequestStatusPillEditor
                    selectedPill={selectedPill}
                    vertical={true}
                    clickHandler={(clicked: PillListType) => {
                      if (clicked === 'CONFIRMED') {
                        if (selectedPill === 'Pre-tour' || selectedPill === 'On-tour' || selectedPill === 'Post-tour' || selectedPill === 'Archived') {
                          // do nothing
                        } else {
                          setSelectedPill('Pre-tour')
                        }
                      } else {
                        setSelectedPill(clicked)
                      }
                    }}
                  />
                  <div className='tw-flex tw-flex-col tw-gap-1'>
                    <ButtonTW variant='blue_outline' onClick={() => {
                      if (!selectedPill) {
                        setDbError('No status selected when clicking Save')
                        return
                      }
                      const { userAction, updateObj } = saveTourStatusToDb_getUpdateObj(selectedPill)

                      const userSimple = getUserSimpleUid(userDetails)

                      autosaveNewStep(userAction, updateObj, 'UNDOWALL') // NOT undoable, as it's not a regular editing action in the CRUD
                        .catch((err) => setDbError(`Autosave tourrequest id=${tourrequest.id} action=[${userAction}]`, err))

                      setShownTourStatusPopup(null)
                    }}>Save</ButtonTW>
                    <ButtonTW variant='blue_outline' onClick={() => {
                      setShownTourStatusPopup(null)
                    }}>Cancel</ButtonTW>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </td>
      {showAllColumns && (
        <>
          <td>{tourrequest.numOfPax}</td>
          <td>{dateFormatJpShortWithTime(tourrequest.dateCreated)}</td>
          <td>{dateFormatJpShortWithTime(tourrequest.dateModified)}</td>
        </>
      )}
      <td>
        {/* <HighlightField tourrequest={tourrequest} fieldname='userCreatedName' />
        {tourrequest.userModifiedUid !== tourrequest.userCreatedUid
          ? <> (<HighlightField tourrequest={tourrequest} fieldname='userModifiedName' />)</>
          : <></>} */}
        {strTravelDesigners}
      </td>
      {showAllColumns && (
        <>
          <td>
            <RequestStatusIcon {...tourrequest} />
          </td>
          <td className='text-end pe-3'>{formatNum(tourrequest.salesInfo2?.quotations?.at(-1)?.quotedAmount)}</td>
        </>
      )}
      {showPayments && (
        <td>
          {hasPayments && (
            <div className='tw-flex'>
              <div className='_left_expand_button'>
                <button className='tw-border-0 tw-bg-transparent'
                  onClick={() => {
                    setExpandPaymentsTable(!expandPaymentsTable)
                  }}>
                  <i className={`bi ${expandPaymentsTable ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                </button>
              </div>
              <div className='_right_textline_or_table'>
                {expandPaymentsTable ? (
                  <table className='table miniPaymentsTable'>
                    <tbody>
                      {payments.map((payment, index) => (
                        <tr key={index} className={!payment.isPaid ? 'notPaid' : (paymentsOk && payment.isPaid) ? 'paymentFullyReceived' : ''}>
                          <td>
                            {payment.paymentType}
                          </td>
                          <td>
                            {formatNum(payment.paymentAmount)}
                          </td>
                          <td>
                            {payment.isPaid
                              ? (payment.datePaymentReceived ? dateFormatJp(payment.datePaymentReceived) : 'Payment date missing')
                              : 'Not paid'}
                          </td>
                          {userrole_canEditFreee(userDetails.roles) && setEditedCell && (
                            <td style={{ color: freeeUnlocked ? '#4488ff' : undefined }}>
                              <div style={{ color: 'gray', fontSize: 'small', fontStyle: 'italic' }}>Freee status</div>
                              <FreeeInputCell
                                freeeUnlocked={freeeUnlocked ?? false}
                                checked={payment.freeeStatus?.checked ?? false}
                                memorandum={payment.freeeStatus?.memorandum ?? ''}
                                callbackUpdateDoc={async (freeeStatus) => {
                                  const function_data = {
                                    tourrequestId: tourrequest.id,
                                    paymentIndex: index,
                                    freeeChecked: freeeStatus.checked,
                                    freeeMemorandum: freeeStatus.memorandum,
                                  }
                                  console.log('calling cloud function with data:')
                                  console.log(function_data)
                                  try {
                                    const result = await tourrequests_setFreeeOnRequestPayment(function_data)
                                    console.log('cloud function tourrequests_setFreeeOnRequestPayment returned', result)
                                  } catch (err) {
                                    setDbError(`Setting freee status ${tourrequest.id}_payment_${index} using cloud function tourrequests_setFreeeOnRequestPayment`, err)
                                  }
                                }}
                                editedCell={editedCell ?? null}
                                setEditedCell={setEditedCell}
                                cellid={`${tourrequest.id}_payment_${index}`}
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    {payments.length} {payments.length === 1 ? 'payment' : 'payments'}, total {formatNum(arraySum(payments.map((p) => p.paymentAmount || 0)))}
                  </div>
                )}
              </div>
            </div>
          )}

          <RequestExpenseMiniTable tourrequest={tourrequest} show={showExpensesMiniTable} />

        </td>
      )}
      {showAllColumns && (
        <>
          <td style={{ backgroundColor: '#d6e7ff', border: '2px solid black' }}>
            {si2 && (
              <div>
                {si2.quotations.length > 0 && (
                  <>
                    <h6 className='mt-4 mb-2'>Quotations</h6>

                    <table className='table column-borders mb-1' style={{ width: '30em' }}>
                      <thead>
                        <tr>
                          <th>Date sent to customer</th>
                          <th>Quotation amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {si2.quotations.map((quotation, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                { // should be a JST date (midnight) but some older data has an time of day -> investigate
                                  quotation.dateQuotationSent && (
                                    isMidnightJST(quotation.dateQuotationSent)
                                      ? dateFormatJp(quotation.dateQuotationSent)
                                      : dateFormatJpShortWithTime(quotation.dateQuotationSent)
                                  )
                                }
                              </td>
                              <td>
                                {formatNum(quotation.quotedAmount)}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </>
                )}

                {si2.payments.length > 0 && (
                  <>
                    <h6 className='mt-4 mb-2'>Invoices and payments</h6>

                    <table className='table column-borders mb-1'>
                      <thead>
                        <tr>
                          <th>Date invoice sent</th>
                          <th>Type</th>{/* [deposit/balance/fullamount] */}
                          <th>Percent</th>
                          <th>Amount</th>
                          <th>Date due</th>
                          <th>Payment method</th>
                          <th>Payment received?</th>
                          <th>Date payment received</th>
                        </tr>
                      </thead>
                      <tbody>
                        {si2.payments.map((payment, index) => {

                          const paymentDateMissing = (payment.isPaid && !payment.datePaymentReceived)

                          return (
                            <tr key={index}>
                              <td>
                                { // should be a JST date (midnight) but some older data has an time of day -> investigate
                                  payment.dateInvoiceSent && (
                                    isMidnightJST(payment.dateInvoiceSent)
                                      ? dateFormatJp(payment.dateInvoiceSent)
                                      : dateFormatJpShortWithTime(payment.dateInvoiceSent)
                                  )
                                }
                              </td>
                              <td>
                                {payment.paymentType}
                              </td>
                              <td>
                                {payment.percent}
                                {payment.percent ? '%' : ''}
                              </td>
                              <td>
                                {formatNum(payment.paymentAmount)}
                              </td>
                              <td>
                                {dateFormatJp(payment.dateDue)}
                              </td>
                              <td>
                                {payment.paymentMethod}
                              </td>
                              <td className='text-center'>
                                <Form.Check type='checkbox' id={`chkInvoicePaid_${tourrequest.id}_${index}`} checked={payment.isPaid} readOnly />
                              </td>
                              <td className={paymentDateMissing ? 'dateMissing' : undefined}>
                                {payment.datePaymentReceived && dateFormatJp(payment.datePaymentReceived)}
                                {paymentDateMissing && <b>Please input payment date</b>}
                              </td>
                            </tr>
                          )
                        })}

                      </tbody>
                    </table>
                  </>
                )}

                {si2.note && (
                  <>
                    <h6 className='mt-4 mb-2'>Note</h6>
                    <Form.Control as='textarea' rows={5} value={si2.note} readOnly />
                  </>
                )}

              </div>
            )}
          </td>
          <td>{tourrequest.salesInfo2?.quotations?.length}</td>
          <td>{tourrequest.statusConfirmed ? <i className='bi bi-check-square'></i> : ''}</td>
          <td>{tourrequest.statusReceivedDeposit ? <i className='bi bi-check-square'></i> : ''}</td>
          <td>{tourrequest.statusReceivedTotalTripFee ? <i className='bi bi-check-square'></i> : ''}</td>
          <td><HighlightField tourrequest={tourrequest} fieldname='userCreatedUid' /></td>
          <td><HighlightField tourrequest={tourrequest} fieldname='userCreatedEmail' /></td>
          <td><HighlightField tourrequest={tourrequest} fieldname='userCreatedName' /></td>
          <td><HighlightField tourrequest={tourrequest} fieldname='userModifiedUid' /></td>
          <td><HighlightField tourrequest={tourrequest} fieldname='userModifiedEmail' /></td>
          <td><HighlightField tourrequest={tourrequest} fieldname='userModifiedName' /></td>
          <td><div className='scrollable-text'><HighlightField tourrequest={tourrequest} fieldname='specialOccasion' /></div></td>
          <td><HighlightField tourrequest={tourrequest} fieldname='guidePreference' /></td>
          <td><HighlightField tourrequest={tourrequest} fieldname='guideLanguage' /></td>
          <td><div className='scrollable-text'><HighlightField tourrequest={tourrequest} fieldname='dietaryRequirements' /></div></td>
          <td><div className='scrollable-text'><HighlightField tourrequest={tourrequest} fieldname='customersInterests' /></div></td>
          <td><div className='scrollable-text'><HighlightField tourrequest={tourrequest} fieldname='requestComments' /></div></td>
          <td><HighlightField tourrequest={tourrequest} fieldname='updatedComment' /></td>

          <td>
            {tourrequest.accommodationStars.join(', ')}
          </td>
          <td>
            {tourrequest.accommodationPreference.join(', ')}
          </td>
          <td>
            {tourrequest.travellers.length > 0 && (
              <>
                <ButtonTW variant='link' onClick={(e) => {
                  if (showTravellers)
                    setTravellersShownReqIdList((list) => list.filter((id) => id !== tourrequest.id))
                  else
                    setTravellersShownReqIdList((list) => [...list, tourrequest.id])
                }}>{showTravellers ? 'hide' : 'show'} {tourrequest.travellers.length}</ButtonTW>
                {showTravellers && (
                  <ul>
                    {tourrequest.travellers.map((t, index) => (
                      <li key={index}>
                        {t.name} (age: {t.age}) (passport: {t.passportInfoReceived ? '✅' : '❌'})
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </td>
        </>
      )}
      <td className='actions'>
        {!hideEditButton && (
          <>
            {(tourrequest.status === '' && (userrole_isAdmin(userDetails.roles) || tourrequest.userCreatedUid === userDetails.id)) && (
              <ButtonTW variant='blue_outline' onClick={() => {
                if (!window.confirm('Are you sure you want to delete this request?'))
                  return

                const updateObj: Partial<TourRequestType> = {
                  status: 'TRASH',
                }

                autosaveNewStep('Send to TRASH', updateObj, 'UNDOWALL')
                  .catch((error) => setDbError('Sending tour request to TRASH', error))
              }}>Delete</ButtonTW>
            )}
            {(isTrash && userrole_isAdmin(userDetails.roles)) && (
              <div style={{ display: 'flex', gap: '0.5em' }}>
                <ButtonTW variant='bsRed' onClick={() => {
                  if (!window.confirm('Are you sure you want to permanently delete this request?'))
                    return

                  const updateObj: Partial<TourRequestType> = {
                    _isDeleted: true,
                    status: 'DELETED',
                  }

                  autosaveNewStep('DELETE', updateObj, 'UNDOWALL')
                    .catch((error) => setDbError('Deleting tour request', error))
                }}>Delete permanently</ButtonTW>
                <ButtonTW variant='bsOrange' onClick={() => {
                  if (!window.confirm('Are you sure you want to restore this request?'))
                    return

                  const updateObj: Partial<TourRequestType> = {
                    status: '',
                  }

                  autosaveNewStep('RESTORE', updateObj, 'UNDOWALL')
                    .catch((error) => setDbError('Deleting tour request', error))
                }}>Restore</ButtonTW>
              </div>
            )}
          </>
        )}
      </td>
    </tr>
  )
}
