import { QuerySnapshot, collection, deleteField, doc, onSnapshot, query, serverTimestamp, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch'
import { EditableField } from 'src/components/EditableField/EditableField'
import { EditableFieldTypeahead } from 'src/components/EditableFieldTypeahead/EditableFieldTypeahead'
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList'
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull'
import { useAppContext } from 'src/hooks/useAppContext'
import { UserDetailsType, UserPermissionType, UserSimpleTeamUidType, userTeamList, userTeamNonEmployeeList } from 'src/types/types_user'
import { dateformatMDasMMMD, dateutcFormatMD } from 'src/util/dateformattools'
import { dateFormatUserFriendly } from 'src/util/datelayouttools'
import { getDateutc } from 'src/util/datetools'
import { getPermission_from_roles, permissionListAll, userrole_isDev } from 'src/util/user_roles'
import { convertUserDetailsDates, serverTimestampAsDate } from 'src/util/util_firestoredates'
import { log_db_read, log_db_write } from 'src/util/util_log'
import { luhnCodePoints } from 'src/util/util_luhn'
import { saveProfilePhotoBase } from 'src/util/util_saveprofilephoto'
import { getUserListSimpleUid } from '../ExpenseSheet/util_getuserlist'
import { UserListEditPermissions } from './UserListEditPermissions'
import { UserListPermissionsSummary } from './UserListPermissionsSummary'
import './userlist.css'
import { useEmergencyPhoneList } from './util_emergencyphonelist'
import { getUserComparer } from './util_userlist'


function findDuplicates(arr: string[]) {
  // https://stackoverflow.com/a/840808/
  // edited to not return empty values
  const sorted_arr = arr.slice().sort();
  const results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i] && sorted_arr[i] === sorted_arr[i + 1]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
}

export function UserList() {

  const { db, userDetails, storage, setDbError, cloudFunctions } = useAppContext()

  const [userDetailsList, setUserDetailsList] = useState<UserDetailsType[]>()
  const [permissionDict, setPermissionDict] = useState<Map<string, UserPermissionType>>()

  const [editedCell, setEditedCell] = useState<string | null>(null) // id of table + '_' + id of invoice being edited + '_' + name of field being edited

  const [showUserIds, setShowUserIds] = useState(false)
  const [showForcePhotoRefreshButton, setShowForcePhotoRefreshButton] = useState(false)

  // exclude 9 as it's reserved for users with no assigned code
  const possibleSingleCharacterCodes = luhnCodePoints.replace('9', '').split('')
  possibleSingleCharacterCodes.unshift('\u00A0') // to delete a user's single character code

  // get user list
  useEffect(() => {
    log_db_read({ db, userDetails, logkey: 'db_read.list_users', desc: 'List users' })

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const users = snapshot.docs.map((doc) => {
        const user = { ...doc.data(), id: doc.id } as UserDetailsType
        convertUserDetailsDates(user)
        return user
      })
      setUserDetailsList(users)
    }

    const q = query(collection(db, 'users'));
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting user list', err));

    return unsubscribe
  }, [db, setDbError, userDetails])


  // get permissions
  useEffect(() => {
    const processSnapshot = function (snapshot: QuerySnapshot) {
      // store permissions in dictionary
      const permissionDict = new Map<string, UserPermissionType>()

      for (const doc of snapshot.docs) {
        const permission = { ...doc.data(), id: doc.id } as UserPermissionType
        permissionDict.set(doc.id, permission)
      }

      setPermissionDict(permissionDict)
    }

    const q = query(collection(db, 'permissions'));
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting permission list', err));

    return unsubscribe
  }, [db, setDbError])

  const userListSimple = useMemo(() => getUserListSimpleUid(userDetailsList), [userDetailsList])

  const emergencyPhoneList = useEmergencyPhoneList()

  // *** all hooks above ***

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['user list', userDetailsList && userListSimple],
    ['permissions', permissionDict],
    ['emergency phones', emergencyPhoneList],
  ])
  if (!userDetailsList || !userListSimple || !permissionDict || !emergencyPhoneList)
    return loadingSpinner

  const dupeSingleCharacterCodes = findDuplicates(userDetailsList.map((user) => user.singleCharacterCode || ''))

  userDetailsList.sort(getUserComparer({ guidesFirst: false }))


  const issueList: { user: UserDetailsType, userIssues: string[] }[] = []
  for (const user of userDetailsList) {
    const userIssues = []

    const isNonEmployee =
      (userTeamNonEmployeeList as readonly string[]).includes(user.teamName)
      || user.email === 'support@80days.co.jp'
    // non-employee doesn't need freee number or name

    if (isNonEmployee) {
      // non-employee issues
      if (user.singleCharacterCode)
        userIssues.push(`Single character code [${user.singleCharacterCode}] assigned to non-employee`)
    } else {
      // employee issues
      if (!user.teamName)
        userIssues.push('No team assigned')
      if (!user.freeeNumber)
        userIssues.push('No Freee number assigned')
      if (!user.freeeName)
        userIssues.push('No Freee name assigned')
    }

    if (userIssues.length > 0) {
      issueList.push({ user, userIssues })
    }
  }


  return (
    <div className='p-4'>
      <Helmet><title>Admin Users</title></Helmet>
      <h3 className='mb-3'>User list</h3>

      <div>
        <h5>Single character codes table</h5>
        <table className='table singleCharacterCodesTable'>
          <thead>
            <tr>
              {luhnCodePoints.split('').map((code, index) => <th key={code}>{code}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {luhnCodePoints.split('').map((code, index) => {
                const usersWithCode = userDetailsList.filter((user) => user.singleCharacterCode === code)
                let userString = usersWithCode.map((user) => user.displayNameEn).join(', ')
                if (code === '9')
                  userString = '[Reserved]'
                return <td key={code} className={usersWithCode.length > 1 ? 'text-danger' : ''}>{userString}</td>
              })}
            </tr>
          </tbody>
        </table>
      </div>

      <div style={{ display: 'flex', gap: '2em' }}>
        <CheckboxSwitch id='switchShowIds' label='Show user IDs' className='mb-3' checked={showUserIds} onChange={(e) => setShowUserIds(e.target.checked)} />

        {userrole_isDev(userDetails.roles) && (
          <CheckboxSwitch id='switchShowForceButton' label='Show button to force photo refresh' className='mb-3' checked={showForcePhotoRefreshButton} onChange={(e) => setShowForcePhotoRefreshButton(e.target.checked)} />
        )}
      </div>

      {issueList.length > 0 && (
        <section className='tw-py-8'>
          <h5 className='tw-text-red-800'>Outstanding issues that must be addressed</h5>
          <table className='tw-bg-red-200 [&>*>tr>*]:tw-p-3 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-500'>
            <thead>
              <tr className='tw-bg-red-300'>
                <th>User</th>
                <th>Team</th>
                <th>Issues</th>
              </tr>
            </thead>
            <tbody>
              {issueList.map((userEntry) => (
                <tr key={userEntry.user.id}>
                  <td>
                    <div>{userEntry.user.displayNameEn}</div>
                  </td>
                  <td>
                    {userEntry.user.teamName}
                  </td>
                  <td>
                    <ul>
                      {userEntry.userIssues.map((issue, index) => {
                        return <li key={index}>{issue}</li>
                      })}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}

      <table className='table user-list'>
        <thead>
          <tr>
            {showUserIds && (
              <th>ID</th>
            )}
            <th>Photo</th>
            <th>Display name</th>
            <th>Full name</th>
            <th>E-mail</th>
            <th>Single character code</th>
            <th>Team</th>
            <th>Reports to</th>
            <th>Guide data</th>
            <th>Role</th>
            <th>Emergency phone</th>
            <th>Freee num.</th>
            <th>Freee name</th>
            <th>Date created</th>
            <th>Date modified</th>
            <th>Last login</th>
          </tr>
        </thead>
        <tbody>
          {userDetailsList.map((user, index) => {

            let permissionError = '';
            const permissionData = permissionDict.get(user.id)
            if (!permissionData) {
              // happens for new users just after they sign in for the first time
              permissionError += '[New user: No permissions defined] '
            } else {
              // 1. check the expected permissions are there and have the correct values
              for (const perm of permissionListAll) {
                if (!(perm in permissionData)) {
                  permissionError += `[${perm} is missing] `
                } else {
                  const expectedPerm = getPermission_from_roles(perm, user.roles)
                  if (permissionData[perm] !== expectedPerm) {
                    permissionError += `[${perm} is incorrect, expecting ${expectedPerm} but currently has ${permissionData[perm]}] `
                  }
                }
              }

              // 2. check there are no excess permissions
              const keys = Object.keys(permissionData)
              const otherKeys = ['email', 'id', 'dateModified', '_aliasUid']
              for (const key of keys) {
                if (!permissionListAll.find((p) => p === key) && !otherKeys.includes(key)) {
                  permissionError += `[excess permission ${key}] `
                }
              }
            }

            const isDifferentTeam = index === 0 || userDetailsList[index - 1].teamName !== user.teamName

            const largePhotoDownloadURL =
              user.photoLargeFileDownloadURL
              || user.photoFileDownloadURL

            const isEmployee = user.teamName !== 'Other'
              && user.teamName !== 'External accountant'
              && user.teamName !== 'Former employees'

            return (
              <tr key={user.id} className={`${permissionError ? 'permission-error' : !user.email.endsWith('@80days.co.jp') ? 'non-80-days' : ''} ${isDifferentTeam ? 'thick-top-border' : ''}`}>
                {showUserIds && (
                  <td>
                    <div>{user.id}</div>
                    <div>
                      <button className='tw-border-0 tw-underline tw-text-blue-700 tw-bg-transparent tw-p-0 tw-m-0' onClick={() => {
                        const updateObj: Partial<UserPermissionType> = {
                          _aliasUid: user.id,
                        }
                        updateDoc(doc(db, 'permissions', userDetails.id), updateObj)
                          .then(() => {
                            location.href = '/'
                          })
                      }}>Browse as this user</button>
                    </div>

                    <ButtonTW variant='link' className='tw-text-xs' onClick={() => {
                      setShowUserIds(false)
                    }}>hide id</ButtonTW>

                  </td>
                )}
                <td>
                  {/*we used to use user.photoURL but google was not happy and sending 403 'rate limit' errors, so now we get the image from cache*/}
                  {user.photoFileDownloadURL ? (
                    <a href={largePhotoDownloadURL} target='_blank' rel='noreferrer'>
                      <img width={48} height={48} style={{ border: '1px solid #888', width: '4em', height: '4em', overflow: 'hidden' }} src={user.photoFileDownloadURL} className='rounded-circle' alt={'' /*`Google profile pic of ${user.displayNameEn}`*/} />
                    </a>
                  ) : (

                    // button to manually cache the photo
                    // (used at the beginning when most users hadn't logged in since caching was implemented)
                    // (also used just after transfering 'users' collection from PROD to TEST and restoring the profile pics on TEST)
                    <button className='btn btn-outline-secondary btn-sm' onClick={(e) => {
                      const doOnServer = true
                      // When getting the photo on the client, we sometimes get CORS errors from Google.
                      // Doing it on the server bypasses these errors.
                      if (doOnServer) {
                        const func = httpsCallable(cloudFunctions, 'refreshPhotoIfNeeded')
                        func({ uid: user.id })
                          .then((result) => console.log('refreshPhotoIfNeeded result', result.data))
                      } else {
                        window.setTimeout(() => {
                          saveProfilePhotoBase(db, storage, user.id, user.email, user.photoURL)
                            .catch((err) => alert(`Error:\n  [${err}]\nURL:\n  [${user.photoURL}]`))
                        }, 1000)
                      }
                    }}>save</button>

                  )}

                  {showForcePhotoRefreshButton && (
                    <button className='btn btn-outline-secondary btn-sm' onClick={(e) => {
                      const func = httpsCallable(cloudFunctions, 'refreshPhotoIfNeeded')
                      func({ uid: user.id })
                        .then((result) => console.log('refreshPhotoIfNeeded result', result.data))
                    }}>force</button>
                  )}

                  {!showUserIds && (
                    <ButtonTW variant='link' className='tw-text-xs' onClick={() => {
                      setShowUserIds(true)
                    }}>show id</ButtonTW>
                  )}
                </td>
                <td>
                  <EditableField
                    tableid='userlist'
                    rowid={user.id}
                    fieldname='displayNameEn'
                    validationType=''
                    currentValue={user.displayNameEn}
                    isClickableToEdit={userrole_isDev(userDetails.roles)}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    useSpan={false}
                    callbackCommitChange={(value) => {
                      if (!value)
                        return

                      const updateObj: Partial<UserDetailsType> = {
                        displayNameEn: value,
                        dateModified: serverTimestampAsDate(),
                      }

                      updateDoc(doc(db, 'users', user.id), updateObj)
                      log_db_write({ db, userDetails, logkey: 'db_write.users.set_displayNameEn', desc: `Set user displayNameEn to [${value}] for user ${user.id} ${user.email}` })

                      setEditedCell('')
                    }}
                    hasButtonForEditing={true}
                  />
                </td>
                <td>
                  <EditableField
                    tableid='userlist'
                    rowid={user.id}
                    fieldname='displayName'
                    validationType=''
                    currentValue={user.displayName}
                    isClickableToEdit={userrole_isDev(userDetails.roles)}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    useSpan={false}
                    callbackCommitChange={(value) => {
                      if (!value)
                        return

                      const updateObj: Partial<UserDetailsType> = {
                        displayName: value,
                        dateModified: serverTimestampAsDate(),
                      }

                      updateDoc(doc(db, 'users', user.id), updateObj)
                      log_db_write({ db, userDetails, logkey: 'db_write.users.set_displayName', desc: `Set user displayName to [${value}] for user ${user.id} ${user.email}` })

                      setEditedCell('')
                    }}
                    hasButtonForEditing={true}
                  />
                </td>
                <td>{user.email}{permissionError && (
                  <div>
                    <b>PERMISSION ERROR {permissionError}</b>
                  </div>
                )}</td>
                <td className={(user.singleCharacterCode && dupeSingleCharacterCodes.includes(user.singleCharacterCode)) ? 'text-danger' : ''}>
                  <EditableFieldTypeahead
                    tableid='userlist'
                    rowid={user.id}
                    fieldname='singleCharacterCode'
                    currentValue={user.singleCharacterCode ?? ''}
                    isClickableToEdit={true}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      if (dbvalue === '\u00A0')
                        dbvalue = ''

                      const updateObj: Partial<UserDetailsType> = {
                        singleCharacterCode: dbvalue,
                        dateModified: serverTimestampAsDate(),
                      }
                      updateDoc(doc(db, 'users', user.id), updateObj)
                      log_db_write({ db, userDetails, logkey: 'db_write.users.set_singleCharacterCode', desc: `Set singleCharacterCode to [${dbvalue}] for user ${user.id} ${user.email}` })
                      setEditedCell('')
                    }}
                    typeaheadOptionsList={possibleSingleCharacterCodes}
                    // menuMinWidth='7.5rem'
                    hasButtonForEditing={true}
                  />
                </td>
                <td>
                  <Form.Select id={`dropdown_teamname_${user.id}`} style={{ width: '10em' }} value={user.teamName || ''} onChange={(e) => {
                    const teamName = userTeamList.find((team) => team === e.target.value)
                    if (!teamName)
                      return
                    const updateObj: Partial<UserDetailsType> = {
                      teamName,
                      dateModified: serverTimestampAsDate(),
                    }
                    updateDoc(doc(db, 'users', user.id), updateObj)
                    log_db_write({ db, userDetails, logkey: 'db_write.users.set_teamName', desc: `Set teamName to [${e.target.value}] for user ${user.id} ${user.email}` })
                  }}>
                    {['', ...userTeamList].map((team, index) => {
                      return <option key={team} value={team}>{team}</option>
                    })}
                  </Form.Select>
                </td>
                <td>
                  <TypeaheadUserList
                    id={`select-manager-${user.id}`}
                    multiple={false}
                    onChange={(array: UserSimpleTeamUidType[]) => {
                      // remove from previous manager
                      if (user.manager) {
                        updateDoc(doc(db, 'users', user.manager.uid), {
                          [`directreports.${user.id}`]: deleteField(),
                        })
                      }
                      const selected = array.length ? array[0] : null
                      const updateObj: Partial<UserDetailsType> = {
                        manager: selected ? {
                          uid: selected.uid,
                          email: selected.email,
                          name: selected.name,
                        } : null,
                        dateModified: serverTimestampAsDate(),
                      }
                      updateDoc(doc(db, 'users', user.id), updateObj)

                      // add to new manager
                      if (selected) {
                        updateDoc(doc(db, 'users', selected.uid), {
                          [`directreports.${user.id}`]: true,
                        })
                      }

                      log_db_write({ db, userDetails, logkey: 'db_write.users.set_manager', desc: `Set manager to [${selected?.uid} ${selected?.email}] for user ${user.id} ${user.email}` })
                    }}
                    userList={userListSimple}
                    selected={user.manager ? userListSimple.filter((u) => u.uid === user.manager!.uid) : []}
                  />
                </td>
                <td>
                  {user.teamName === 'Guide' && (
                    <>
                      <div><span className='tw-text-slate-400'>Num:</span>{' '}
                        <EditableField
                          tableid='userlist'
                          rowid={user.id}
                          fieldname='guideNumer'
                          validationType='number'
                          currentValue={user.guideNumber ?? ''}
                          isClickableToEdit={true}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(value) => {
                            const updateObj: Partial<UserDetailsType> = {
                              guideNumber: value,
                              dateModified: serverTimestampAsDate(),
                            }
                            updateDoc(doc(db, 'users', user.id), updateObj)
                            log_db_write({ db, userDetails, logkey: 'db_write.users.set_guide_number', desc: `Set guide number to [${value}] for user ${user.id} ${user.email}` })

                            setEditedCell('')
                          }}
                          hasButtonForEditing={false}
                          useSpan={true}
                        />
                      </div>
                      <div><span className='tw-text-slate-400'>Year starts on:</span>{' '}
                        <EditableField
                          tableid='userlist'
                          rowid={user.id}
                          fieldname='guideYearStartsOn'
                          validationType=''
                          currentValue={user.guideYearStartsOn ?? ''}
                          isClickableToEdit={true}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          useSpan={true}
                          callbackCommitChange={(value) => {

                            const getFormatted = (value: string) => {
                              if (!value)
                                return
                              const match = value.match(/^(\d{1,2})\/(\d{1,2})$/)
                              if (!match)
                                return
                              const month = Number(match[1])
                              const day = Number(match[2])
                              if (month < 1 || month > 12 || day < 1 || day > 31)
                                return
                              const formatted = `${month}/${day}`
                              const dateutc = getDateutc(2024, month, day)
                              const formatted2 = dateutcFormatMD(dateutc)
                              if (formatted !== formatted2)
                                // prevents inputs such as 4/31
                                return
                              return formatted
                            }

                            const formatted = getFormatted(value)
                            if (!formatted) {
                              alert('Invalid format. Please use MM/DD')
                              return
                            }
                            const updateObj: Partial<UserDetailsType> = {
                              guideYearStartsOn: formatted,
                              dateModified: serverTimestampAsDate(),
                            }
                            updateDoc(doc(db, 'users', user.id), updateObj)
                            log_db_write({ db, userDetails, logkey: 'db_write.users.set_guide_yearStart', desc: `Set guide year start to [${formatted}] for user ${user.id} ${user.email}` })

                            setEditedCell('')
                          }}
                          hasButtonForEditing={false}
                          getDisplayValue={(value) => {
                            return dateformatMDasMMMD(value)
                          }}
                        />
                      </div>
                      <div>
                        <span className='tw-text-slate-400'>PTO:</span>{' '}
                        <EditableField
                          tableid='userlist'
                          rowid={user.id}
                          fieldname='guidePto'
                          validationType='number'
                          currentValue={user.guidePaidTimeOff ?? ''}
                          isClickableToEdit={true}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          useSpan={true}
                          callbackCommitChange={(value) => {
                            if (typeof value !== 'number') {
                              alert('must be a number')
                              return
                            }
                            if (value < 10 || value > 20) {
                              alert('must be between 10 and 20')
                              return
                            }

                            const updateObj: Partial<UserDetailsType> = {
                              guidePaidTimeOff: value,
                              dateModified: serverTimestampAsDate(),
                            }
                            updateDoc(doc(db, 'users', user.id), updateObj)
                            log_db_write({ db, userDetails, logkey: 'db_write.users.set_guide_paidTimeOff', desc: `Set guide paid time off to [${value}] for user ${user.id} ${user.email}` })

                            setEditedCell('')
                          }}
                          hasButtonForEditing={false}
                        />
                      </div>
                    </>
                  )}
                </td>
                <td>
                  <UserListEditPermissions
                    user={user}
                  />
                </td>
                <td>
                  <Form.Select id={`dropdown_emergencyphone_${user.id}`} style={{ width: '10.5em' }} value={user.preferences?.defaultEmergencyPhone || ''} onChange={(e) => {
                    updateDoc(doc(db, 'users', user.id), {
                      ['preferences.defaultEmergencyPhone']: e.target.value,
                      dateModified: serverTimestamp(),
                    })
                    log_db_write({ db, userDetails, logkey: 'db_write.users.set_defaultEmergencyPhone', desc: `Set default emergency phone to [${e.target.value}] for user ${user.id} ${user.email}` })
                  }}>
                    {['', ...emergencyPhoneList].map((phoneName, index) => {
                      return <option key={phoneName} value={phoneName}>{phoneName}</option>
                    })}
                  </Form.Select>
                </td>
                <td style={userDetailsList.filter((u) => u.freeeNumber === user.freeeNumber).length > 1 ? { color: 'red' } : undefined}>
                  <div style={isEmployee && !user.freeeNumber ? { backgroundColor: 'orange' } : undefined}>
                    <EditableField
                      tableid='userlist'
                      rowid={user.id}
                      fieldname='freeeNumber'
                      validationType='number'
                      currentValue={user.freeeNumber ?? ''}
                      isClickableToEdit={true}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(value) => {
                        const updateObj: Partial<UserDetailsType> = {
                          freeeNumber: value,
                          dateModified: serverTimestampAsDate(),
                        }
                        updateDoc(doc(db, 'users', user.id), updateObj)
                        log_db_write({ db, userDetails, logkey: 'db_write.users.set_freeeNumber', desc: `Set freee number to [${value}] for user ${user.id} ${user.email}` })

                        setEditedCell('')
                      }}
                      hasButtonForEditing={false}
                    />
                  </div>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <div style={isEmployee && !user.freeeName ? { backgroundColor: 'orange' } : undefined}>
                    <EditableField
                      tableid='userlist'
                      rowid={user.id}
                      fieldname='freeeName'
                      validationType=''
                      currentValue={user.freeeName ?? ''}
                      isClickableToEdit={true}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(value) => {
                        const updateObj: Partial<UserDetailsType> = {
                          freeeName: value.trim(),
                          dateModified: serverTimestampAsDate(),
                        }
                        updateDoc(doc(db, 'users', user.id), updateObj)
                        log_db_write({ db, userDetails, logkey: 'db_write.users.set_freeeName', desc: `Set freee name to [${value.trim()}] for user ${user.id} ${user.email}` })

                        setEditedCell('')
                      }}
                      hasButtonForEditing={false}
                    />
                  </div>
                </td>
                <td>{dateFormatUserFriendly(user.dateCreated)}</td>
                <td>{dateFormatUserFriendly(user.dateModified)}</td>
                <td>{dateFormatUserFriendly(user.dateLastLoggedIn)}</td>
              </tr>
            )
          }
          )}
        </tbody>
      </table>

      {userrole_isDev(userDetails.roles) && (
        <UserListPermissionsSummary
          userList={userDetailsList}
        />
      )}
    </div>
  )
}
