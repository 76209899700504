import { QueryConstraint, QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch'
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList'
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull'
import { useAppContext } from 'src/hooks/useAppContext'
import { useUserListSimple } from 'src/pages/ExpenseSheet/util_getuserlist'
import { ExpensePaymentType, ExpenseSheetType } from 'src/types/types_expensesheet'
import { UserSimpleType } from 'src/types/types_user'
import { addMonthsIso, getFirstOfMonthIso, getTodayIso } from 'src/util/datetools'
import { userrole_canMarkPaid, userrole_isAdmin } from 'src/util/user_roles'
import { convertExpensePaymentDates, convertExpenseSheetDates } from 'src/util/util_firestoredates'
import { compare } from 'src/util/util_misc'
import { ExpenseAccountingMonthlyGrid } from './ExpenseAccountingMonthlyGrid'
import { ModalActionExpensePaymentType, ModalPopupMakeExpensePayment } from './ModalPopupMakeExpensePayment/ModalPopupMakeExpensePayment'
import { PaymentsTable } from './PaymentsTable'
import { UnpaidAdvancePaymentsTable } from './UnpaidAdvancePaymentsTable'
import './expenseaccounting.css'


export function ExpenseAccounting() {

  const { db, userDetails, perm, setDbError } = useAppContext()

  const isAdmin = userrole_isAdmin(userDetails.roles)
  // payment issuer user can view everybody's expenses
  const isPaymentIssuer = userrole_canMarkPaid(userDetails.roles)
  const isTravelDesignerRole = perm('guiding_expenses_view_all')
  const canViewAnyUser = isTravelDesignerRole && perm('view_all_expense_accounting')

  const [showPaidAdvances, setShowPaidAdvances] = useState(true)
  const [simpleAccounting, setSimpleAccounting] = useState(false)

  const [editAccountingDates, setEditAccountingDates] = useState(false)
  const [editedCell, setEditedCell] = useState<string | null>(null)
  const [shownPopup, setShownPopup] = useState<string | null>(null)

  const [hideMonths, setHideMonths] = useState<string[]>([])

  const [modalAction, setModalAction] = useState<ModalActionExpensePaymentType | null>(null)

  const [hoverCell, setHoverCell] = useState<string | null>(null)


  const userListSimple = useUserListSimple()
  const [selectedEmployee, setSelectedEmployee] = useState<UserSimpleType | null>(null)


  const [expenseSheetList, setExpenseSheetList] = useState<ExpenseSheetType[]>()
  useEffect(() => {

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const sheets: ExpenseSheetType[] = []
      for (const docu of snapshot.docs) {
        const sheet = { ...docu.data(), id: docu.id } as ExpenseSheetType
        if (sheet._isDeleted)
          continue

        convertExpenseSheetDates(sheet)
        sheets.push(sheet)
      }

      setExpenseSheetList(sheets)
    }

    const constraints: QueryConstraint[] = []
    if (!isTravelDesignerRole)
      constraints.push(where('userGuideUid', '==', userDetails.id))
    constraints.push(where('_isDeleted', '==', false))

    const q = query(collection(db, 'expensesheets'), ...constraints);
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting expense sheet list', err));

    return unsubscribe

  }, [db, setDbError, isTravelDesignerRole, userDetails.id])


  const [donePayments, setDonePayments] = useState<Map<string, ExpensePaymentType>>()
  useEffect(() => {

    const processSnapshot = function (snapshot: QuerySnapshot) {
      console.log(`NEW SNAPSHOT expensepayments ${snapshot.docs.length}`)
      const dict = new Map<string, ExpensePaymentType>()
      for (const doc of snapshot.docs) {
        const item = { ...doc.data(), id: doc.id } as ExpensePaymentType
        if (item._isDeleted)
          continue

        convertExpensePaymentDates(item)

        const id = item.isAdvance
          ? `advance: ${item.sheetId} ${item.advancePaymentId}`
          : `salary: ${item.userPaymentToUid} ${item.salaryMonth}`

        if (dict.has(id)) {
          // setDbError(`duplicate payment with id [${id}]`)
          // TODO: prevent this from happening
          const userObj = userListSimple?.find((u) => u.id === item.userPaymentToUid)
          console.error(`duplicate payment with id [${id}] for user ${userObj?.email}`)
        }
        dict.set(id, item)
      }

      setDonePayments(dict)
    }

    const constraints: QueryConstraint[] = []
    if (!isTravelDesignerRole)
      constraints.push(where('userPaymentToUid', '==', userDetails.id)) // necessary for permissions if user is a Guide
    constraints.push(
      where('_isDeleted', '==', false),
      where('paymentFor', '==', 'GUIDING_EXPENSE')
    )

    const q = query(collection(db, 'expensepayments'), ...constraints);
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting expense payments list (guiding expenses)', err));

    return unsubscribe

  }, [db, setDbError, isTravelDesignerRole, userDetails.id])


  // ******************** hooks above

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['sheet list', expenseSheetList],
    ['user list', userListSimple],
    ['done payments', donePayments],
  ])
  if (!expenseSheetList || !userListSimple || !donePayments)
    return loadingSpinner

  const userMap = new Map(userListSimple.map((user) => [user.id, user]))


  const set_guidesIds = new Set(expenseSheetList.map((sheet) => sheet.userGuideUid))


  const guides = [...set_guidesIds].map((id) => {
    const user = userMap.get(id)
    if (!user) {
      // happens in TEST environment if new users were not copied over
      console.error('User not found', id)
      return { id, email: id, name: '[User not found]', teamName: 'CEO' }
    }
    return user
  })
  guides.sort((a, b) => compare(a.email, b.email))

  const guidesMenu = [{ id: '_ALL_', name: 'All', email: 'All', teamName: 'CEO' }, ...guides]

  let guidesDisplayed: UserSimpleType[] = []
  if (selectedEmployee) {
    if (selectedEmployee.id === '_ALL_')
      guidesDisplayed = guides
    else
      guidesDisplayed = [selectedEmployee]
  }

  // if user is a guide, they cannot manually change selectedEmployee,
  // so we automatically set selectedEmployee to the current user
  if (!isPaymentIssuer && !selectedEmployee && !canViewAnyUser) {
    // if user has never had an expense sheet, they are missing from the guides array and cannot be displayed:
    const guide = guides.find((guide) => guide.id === userDetails.id)
    if (guide) {
      setSelectedEmployee(guide)
    }
  }


  let minAccountingDateiso = getTodayIso()
  expenseSheetList.forEach((sheet) => {
    if (sheet.status === 'SUBMITTED' || sheet.status === 'APPROVED') {
      const thisDateiso = sheet.accountingDateiso
      if (thisDateiso < minAccountingDateiso)
        minAccountingDateiso = thisDateiso
    }
  })


  const firstOfMonthIso = getFirstOfMonthIso(minAccountingDateiso)

  const currentMonthIso = getFirstOfMonthIso(getTodayIso())


  // add list of months (including PENDING) to `months`, `by_month` keys, `eom_balances` keys
  const months: string[] = []
  const by_month = new Map<string, Map<string, ExpenseSheetType[]>>() // month => guide => sheets
  const eom_balances = new Map<string, Map<string, number>>() // month => guide => eom_balance
  for (let curDateiso = firstOfMonthIso; curDateiso <= currentMonthIso; curDateiso = addMonthsIso(curDateiso, 1)) {
    const monthkey = curDateiso.substring(0, 7)
    months.push(monthkey)
    by_month.set(monthkey, new Map<string, ExpenseSheetType[]>())
    eom_balances.set(monthkey, new Map<string, number>())
  }
  months.push('PENDING')
  by_month.set('PENDING', new Map<string, ExpenseSheetType[]>())
  eom_balances.set('PENDING', new Map<string, number>())


  // populate by_month
  expenseSheetList.forEach((sheet) => {
    let monthkey
    if (sheet.status === 'SUBMITTED' || sheet.status === 'APPROVED') {
      monthkey = sheet.accountingDateiso.substring(0, 7)
    } else {
      monthkey = 'PENDING'
    }

    if (!by_month.get(monthkey)!.has(sheet.userGuideUid))
      by_month.get(monthkey)!.set(sheet.userGuideUid, [])
    by_month.get(monthkey)!.get(sheet.userGuideUid)!.push(sheet)
  })


  // calculate eom_balances here
  // (do not calculate as a side effect during rending because react will not render unnecessary components!!)
  guides.forEach((guide, i_guide) => {
    let balance = 0
    months.forEach((month, i_month) => {
      let monthUserTotal_balance = 0
      const month_guide_sheets = by_month.get(month)!.get(guide.id)
      if (month_guide_sheets) {
        month_guide_sheets.forEach((sheet) => {
          let totalPaid = 0
          sheet.advancePayments.forEach((ap) => {
            if (ap.isDeleted)
              return
            const advancePayment = donePayments.get(`advance: ${sheet.id} ${ap.id}`)
            const paid = advancePayment ? advancePayment.amount : 0
            const amountAssumedToBePaid = advancePayment ? paid : (ap.amount ?? 0)
            totalPaid += amountAssumedToBePaid
          }) // each advance payment
          const balance = sheet.calc.totalExpenses - totalPaid
          monthUserTotal_balance += balance
        })// each sheet
      }
      const curMonthBalance = balance + monthUserTotal_balance
      const salaryPayment = donePayments.get(`salary: ${guide.id} ${month}`)
      const finalBalance = curMonthBalance - (salaryPayment ? salaryPayment.amount : 0)
      eom_balances.get(month)!.set(guide.id, finalBalance)
      balance = finalBalance
    })// each month
  })// each guide


  const tablecells: JSX.Element[] = []

  const baseProps = {
    hoverCell, setHoverCell,
    setModalAction,
    showPaidAdvances, setShowPaidAdvances,
    editedCell, setEditedCell,
    shownPopup, setShownPopup,
    editAccountingDates,
    simpleAccounting,
    donePayments,
    eom_balances,
    hideMonths,
  }

  const getMonthWithCaret = (month: string) => {
    return (
      <>
        {month}
        <span className={`triangle ${hideMonths.includes(month) ? 'triangle-hidemonth' : ''}`} onClick={() => {
          setHideMonths((list) => list.includes(month) ? list.filter((m) => m !== month) : [...list, month])
        }}><i className='bi bi-caret-down'></i></span>
      </>
    )
  }


  guidesDisplayed.forEach((guide) => {

    let firstMonth = true
    let prevMonth: string | null = null

    tablecells.push(
      <div key={`col1_guide_${guide.id}`} className={'colLevel1 topOfLevel1'} style={{ gridRow: `span ${months.length}` }}>{guide.name}</div>
    )

    months.forEach((month, i_month) => {
      const month_guide_sheets = by_month.get(month)!.get(guide.id)
      const nextMonth = i_month < months.length - 1 ? months[i_month + 1] : 'FUTURE'

      tablecells.push(
        <div key={`col2_month_${guide.id}_${month}`} className={`colLevel2 ${firstMonth ? 'topOfLevel1' : 'topOfLevel2'}`}>
          {getMonthWithCaret(month)}
        </div>
      )

      const props = {
        ...baseProps,
        month, guide, month_guide_sheets, firstLevel2: firstMonth, prevMonth, nextMonth,
      }
      const subgrid = (
        <ExpenseAccountingMonthlyGrid
          key={`grid_${month}_${guide.id}`} // key should NOT be included in props object as it is not a prop that is passed down to the component, it's just for iteration
          {...props}
        />
      )
      tablecells.push(subgrid)

      firstMonth = false

      prevMonth = month

    }) // each month
  }) // each guide






  return (
    <div className='container pt-3'>
      <Helmet><title>Expense Accounting</title></Helmet>


      <div className='ms-4 mb-5'>
        <div className='top-explainer'>
          <ul>
            <li>
              Sheets with status <b>Travel Designer Input</b> or <b>Guide Input</b> are classified
              as <b>PENDING</b> and do not appear in any given monthly statement.
            </li>
            <li>
              Sheets that have been <b>submitted</b> by the guide and have
              status <b>Submitted</b> or <b>Approved</b> are placed in their
              corresponding <b>Accounting Month</b>.
              The Accounting Month is the month of the date on which the sheet was submitted by the guide.
              For example, a sheet submitted on 2023/9/29 will appear in the 2023-09 statement,
              while a sheet submitted on 2023/10/2 will appear in the 2023-10 statement.
            </li>
          </ul>
        </div>


        {isPaymentIssuer && (
          <UnpaidAdvancePaymentsTable
            expenseSheetList={expenseSheetList}
            donePayments={donePayments}
            shownPopup={shownPopup}
            setShownPopup={setShownPopup}
          />
        )}


        <div className='mt-3'>
          <div className='userSelector'>
            <TypeaheadUserList
              id='select-employee'
              multiple={false}
              onChange={(array) => {
                setSelectedEmployee(array.length ? array[0] : null)
              }}
              userList={guidesMenu}
              selected={selectedEmployee ? [selectedEmployee] : []}
              disabled={!canViewAnyUser}
            />
          </div>

        </div>

        <div className='mt-3'>
          <CheckboxSwitch id='chkShowPaidAdvances' label='Show advance payments' checked={showPaidAdvances} onChange={(e) => setShowPaidAdvances(e.target.checked)} />
          (Advance payments that have not yet been paid are always shown)
        </div>

        <div className='mt-3'>
          <CheckboxSwitch id='chkSimpleAccounting' label='Show detailed accounting view' checked={!simpleAccounting} onChange={(e) => setSimpleAccounting(!e.target.checked)} />
        </div>

        {isPaymentIssuer && (
          <div className='mt-3'>
            <CheckboxSwitch id='chkEditAccountingDates' label='Edit accounting dates' checked={editAccountingDates} onChange={(e) => setEditAccountingDates(e.target.checked)} />
          </div>
        )}
      </div>



      <div className={`expense-payments ${!showPaidAdvances ? 'collapse-advances' : ''}`}>
        <h3>
          Guide
        </h3>
        <h3>
          Month
        </h3>
        <h3>
        </h3>
        <h3>
        </h3>
        <h3>
        </h3>
        <h3 className='text-center'>
        </h3>
        <h3 className='text-center'>
        </h3>
        <h3 className='text-center'>
        </h3>
        <h3 className='text-center'>
        </h3>
        {tablecells}
      </div>

      {isAdmin && (
        <PaymentsTable
          donePayments={donePayments}
        />
      )}

      <ModalPopupMakeExpensePayment
        modalAction={modalAction}
        setModalAction={setModalAction}
      />


    </div>
  )
}
