
import { DocumentSnapshot, QuerySnapshot, addDoc, collection, doc, getCountFromServer, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { sanitizeId } from 'src/appsettings/appsettings'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch'
import { DeleteButton } from 'src/components/Buttons/DeleteButton'
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator'
import { EditableField } from 'src/components/EditableField/EditableField'
import { EditableFieldDatepicker } from 'src/components/EditableFieldDatepicker/EditableFieldDatepicker'
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList'
import { ModalTW } from 'src/components/Modal/ModalTW'
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull'
import { InfoToolTip } from 'src/components/ToolTip/InfoToolTip'
import { TopWhiteBarEditControls } from 'src/hooks/autosave/TopWhiteBarEditControls'
import { useUndoRedo } from 'src/hooks/autosave/useUndoRedo'
import { autosaveDocument } from 'src/hooks/autosave/util_autosave'
import { useAppContext } from 'src/hooks/useAppContext'
import { addNewAgency, normalizeForLuhn, validateTwoCharacterCode } from 'src/pages/Agencies/util_agencies'
import { DateInput } from 'src/pages/ExpenseSheet/DateInput'
import { DateRangeInput } from 'src/pages/ExpenseSheet/DateRangeInput'
import { calcTourNumDaysIso } from 'src/pages/ExpenseSheet/util_calcamounts'
import { useUserListSimple } from 'src/pages/ExpenseSheet/util_getuserlist'
import { createNewQuotation } from 'src/pages/Quotations/createNewQuotation'
import { addMetadataModifiedQuotations } from 'src/pages/Quotations/QuotationCrud/util_db_quotations'
import { CountryListItemType, RequestClassificationListsType } from 'src/types/objectTypes'
import { AgencySimpleType } from 'src/types/types_agencies'
import { QuotationType } from 'src/types/types_quotation'
import { ExpediaBookingType, TourRequestType, TravellerType } from 'src/types/types_tourrequest'
import { UserSimpleTeamType, UserSimpleUidType } from 'src/types/types_user'
import { dateutcFormatJpShort } from 'src/util/dateformattools'
import { getAge, getTodayIso, getTodayUTC, iso_from_jst0, iso_from_utc0, jst0_from_iso, local0_from_iso, tryParseDateToJst0, utc0_from_iso, utc0_from_local0 } from 'src/util/datetools'
import { userrole_canAddInvoice, userrole_canEditAnyInvoice, userrole_isAdmin } from 'src/util/user_roles'
import { verifyNotDeleted } from 'src/util/util_db_misc'
import { convertQuotationSheetDates, convertTourRequestDates } from 'src/util/util_firestoredates'
import { log_db_read, log_db_write } from 'src/util/util_log'
import { generateCheckCharacter, luhnCodePoints, validateCheckCharacter } from 'src/util/util_luhn'
import { getRandomInt } from 'src/util/util_misc'
import { getUserSimpleUid } from 'src/util/util_users'
import { PillListType } from '../RequestsList/RequestStatusPill/RequestStatusOnePill'
import { RequestStatusPillCurrent } from '../RequestsList/RequestStatusPill/RequestStatusPillCurrent'
import { RequestStatusPillEditor } from '../RequestsList/RequestStatusPill/RequestStatusPillEditor'
import { getSelectedPill, saveTourStatusToDb_getUpdateObj } from '../RequestsList/RequestStatusPill/util_tourrequest_pill'
import { TeamName } from '../TeamName'
import { getBlankTourRequest, getNextRequestNumber } from '../util_tourrequests'
import { EditableFieldTourRequest } from './EditableFieldTourRequest'
import { SalesInfo2Crud } from './SalesInfo2Crud'
import { SalesInformationCrud } from './SalesInformationCrud'
import './tourrequestcrud.css'
import { addMetadataModifiedTourRequest } from './util_db_tourrequests'



function getCodeLetter(tourRequest: TourRequestType) {
  if (!tourRequest.eightyDaysDepartment) {
    return { err: 'First select team/category' }
  }

  if (!tourRequest.customerType) {
    return { err: 'First select customer type' }
  }

  if (tourRequest.eightyDaysDepartment === 'Taiko-Lab')
    return { firstLetter: 'T' }

  const t = tourRequest.customerType
  if (t.startsWith('FIT'))
    return { firstLetter: 'F' }
  else if (t === 'Group' || t === 'Educational')
    return { firstLetter: 'G' }
  else if (t === 'MICE')
    return { firstLetter: 'M' }
  else if (t === 'Other')
    return { firstLetter: 'P' }

  return { err: 'Could not determine first letter of request code' }
}


type ModalActionAddAgencyPopupType = {
  action: 'add_agency',
  newAgencyName: string,
  newAgencyMnemonic: string,
  newAgencyMnemonicError: string | null,
}


interface TourRequestCrudProps {
  action: 'create' | 'edit'
  simpleAgencyList: AgencySimpleType[] | undefined // undefined while loading
}

export function TourRequestCrud({
  action,
  simpleAgencyList,
}: TourRequestCrudProps) {

  if (action !== 'create' && action !== 'edit')
    throw new Error('invalid action')


  const { db, userDetails, setDbError } = useAppContext()

  const [searchParams] = useSearchParams()
  const { tourrequestId } = useParams()

  const navigate = useNavigate()

  const userSimple = useMemo<UserSimpleUidType>(() => {
    return {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    }
  }, [userDetails])

  const [modalAction, setModalAction] = useState<ModalActionAddAgencyPopupType | null>(null)

  const [statusMsg, setStatusMsg] = useState<ReactNode>()

  const setErr = (err: ReactNode) => setStatusMsg(<div className='alert alert-danger'>{err}</div>)
  const setInfo = (msg: ReactNode) => setStatusMsg(<div className='alert alert-primary'>{msg}</div>)
  const setSuccess = (msg: ReactNode) => setStatusMsg(<div className='alert alert-success'>{msg}</div>)
  const setSuccessSaved = () => setSuccess(
    <div>
      Saved.
      <ButtonTW variant='link' onClick={(e) => navigate('/requests/')}>Return to list</ButtonTW>
    </div>
  )

  const [isGeneratingRequestCode, setIsGeneratingRequestCode] = useState(false)

  const [shownPopup, setShownPopup] = useState<string | null>(null)

  const [showKintoneSalesInfo, setShowKintoneSalesInfo] = useState(false)

  const { addToCache, getUndoRedoHistoryChanges } = useUndoRedo<TourRequestType>('tourrequestshistory')

  const [enableEditing, setEnableEditing] = useState(action === 'create')
  const [saveStatus, setSaveStatus] = useState<string>()
  const [editedCell, setEditedCell] = useState<string | null>(null)

  const userIsAllowedToEdit = userrole_canAddInvoice(userDetails.roles)

  if (!userIsAllowedToEdit && enableEditing) {
    // should not be possible??
    setEnableEditing(false)
  }

  const [countryList, setCountryList] = useState<CountryListItemType[]>()


  // the following three 'reset' functions allow to resync the page internal state with the tourrequest values.
  // the reason we need a separate page state in the first place, is because we don't want to store inconsistent/temporary
  // state into the db. For example, if user selected only the tour start date and not the tour end date,
  // we do not want to save that change to the db, but we are forced to update the internal page state in order for
  // the UI (date picker) to function properly.

  const resetAgency = useCallback((tourrequest: TourRequestType) => {
    if (tourrequest.agencyOrPlatformId) {
      setAgencySelectedItems([{ id: tourrequest.agencyOrPlatformId, name: tourrequest.agencyOrPlatform }])
      setAgencyInputText(tourrequest.agencyOrPlatform)
    } else {
      setAgencySelectedItems([])
      setAgencyInputText('')
    }
  }, [])

  const resetCountry = useCallback((tourrequest: TourRequestType, countryList: CountryListItemType[]) => {
    if (tourrequest.country) {
      const label = countryList.find((x) => x.name === tourrequest.country)?.label || tourrequest.country
      setCountrySelectedItems([{ name: tourrequest.country, label }])
      setCountryInputText(label)
    } else {
      setCountrySelectedItems([])
      setCountryInputText('')
    }
  }, [])

  const resetTourDates = useCallback((tourrequest: TourRequestType) => {
    if (tourrequest.dateisoTourStart) {
      setDateinputTourStartDate(local0_from_iso(tourrequest.dateisoTourStart))
    } else {
      setDateinputTourStartDate(null)
    }

    if (tourrequest.dateisoTourEnd) {
      setDateinputTourEndDate(local0_from_iso(tourrequest.dateisoTourEnd))
    } else {
      setDateinputTourEndDate(null)
    }
  }, [])

  const [tourRequest, setTourRequest] = useState<TourRequestType>()
  useEffect(() => {
    if (!countryList) {
      // still loading
      // we need countrylist to be already loaded, in order to call resetCountry
      return
    }

    if (action === 'create') {
      const tourrequest = getBlankTourRequest(userSimple)

      setTourRequest(tourrequest)
      log_db_read({ db, userDetails, logkey: 'db_read.create_tour_request', desc: `Create tour request [${tourrequest.requestNumber}]` })

      resetAgency(tourrequest)
      resetCountry(tourrequest, countryList)
      resetTourDates(tourrequest)

      setEnableEditing(true)
      return
    }

    if (action === 'edit') {
      if (!tourrequestId)
        throw new Error('tourrequestId missing')

      let isLoaded = false

      const processSnapshot = function (snapDoc: DocumentSnapshot) {
        if (!snapDoc.data()) {
          // no tourrequest with this id exists
          setDbError(`Tour request ${tourrequestId} not found`)
          return
        }
        const tourrequest = { ...snapDoc.data(), id: snapDoc.id } as TourRequestType
        verifyNotDeleted(snapDoc.exists(), tourrequest, tourrequestId, setDbError, 'tourrequest')
        convertTourRequestDates(tourrequest)
        setTourRequest(tourrequest)
        console.log('tourRequest', tourrequest)
        if (!isLoaded) {
          log_db_read({ db, userDetails, logkey: 'db_read.open_tour_request', desc: `Open tour request [${tourrequest.requestCode}] [${tourrequestId}]` })
          isLoaded = true
        }
        if (tourrequest.history?.currentStepId) {
          addToCache(tourrequest.history.currentStepId, tourrequest)
        }
        resetAgency(tourrequest)
        resetCountry(tourrequest, countryList)
        resetTourDates(tourrequest)
        if (tourrequest.history?.lastStep === 1) {
          // first time request is opened for editing
          setEnableEditing(true)
        }
      }

      const q = doc(db, 'tourrequests', tourrequestId)
      const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError(`Getting tour request ${tourrequestId}`, err))

      return unsubscribe
    }
  }, [db, action, tourrequestId, setDbError, userDetails, userSimple, addToCache, resetAgency, resetCountry, resetTourDates, countryList])



  const [requestClassificationLists, setRequestClassificationLists] = useState<RequestClassificationListsType>()
  useEffect(() => {
    getDoc(doc(db, 'settings', 'requestClassificationLists'))
      .then((doc) => setRequestClassificationLists(doc.data() as RequestClassificationListsType))
      .catch((err) => setDbError('Getting requestClassificationLists', err))
  }, [db, setDbError])


  const auditExistingCountryData = false
  useEffect(() => {
    if (!auditExistingCountryData)
      return

    getDocs(query(collection(db, 'tourrequests')))
      .then((snapshot) => {
        const langs = new Map()
        const countries = new Map()
        const regions = new Map()
        for (const doc of snapshot.docs) {
          const lang = doc.data().guideLanguage
          const country = doc.data().country
          const region = doc.data().stateOrRegion
          if (!langs.has(lang)) langs.set(lang, 0)
          langs.set(lang, langs.get(lang) + 1)
          if (!countries.has(country)) countries.set(country, 0)
          countries.set(country, countries.get(country) + 1)
          if (!regions.has(region)) regions.set(region, 0)
          regions.set(region, regions.get(region) + 1)
        }

        console.log({ langs, countries, regions })
      })
      .catch((err) => setDbError('Getting languages/countries', err))
  }, [db, setDbError, auditExistingCountryData])


  useEffect(() => {
    getDoc(doc(db, 'settings', 'countryList'))
      .then((doc) => {
        const list: CountryListItemType[] = []
        for (const country of doc.data()!.list) {
          const match = country.match(/^([a-z ]+) \(([a-z ]+)\)$/i)
          if (match) {
            list.push({ label: country, name: match[1] })
          } else {
            list.push({ label: country, name: country })
          }
        }
        setCountryList(list)
      })
      .catch((err) => setDbError('Getting country list', err))
  }, [db, setDbError])


  const userListSimple = useUserListSimple()

  const requestCode = tourRequest?.requestCode

  const [quotationSheetList, setQuotationSheetList] = useState<QuotationType[]>()
  useEffect(() => {
    if (!requestCode)
      return
    if (!userrole_isAdmin(userDetails.roles))
      return

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: QuotationType[] = []
      console.log('Count of quotation sheets', snapshot.docs.length)
      for (const doc of snapshot.docs) {
        const quotationsheet = { ...doc.data(), id: doc.id } as QuotationType
        convertQuotationSheetDates(quotationsheet)
        list.push(quotationsheet)
      }
      setQuotationSheetList(list)
    }

    console.log('retrieving quotation sheets for request code', requestCode)
    const q = query(collection(db, 'quotationsheets'), where('_isDeleted', '==', false), where('requestInfo.requestCode', '==', requestCode))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting quotationsheets list', err));

    return unsubscribe

  }, [db, setDbError, requestCode, userDetails])


  // due to the Typeahead behavior, we need to maintain this parallel state, which is a bit different than the state on tourRequest:
  //  - when an agency has been selected, the below are in sync with the tourRequest
  //  - when user starts editing the typeahead by deleting characters, agencyInputText is the truncated string, and agencySelectedItems
  //    becomes empty, but tourRequest is not changed
  //  - if a different agency is clicked, tourRequest is updated with the new agency, and the below are synced to the tourRequest
  //  - if the textbox is completely cleared, the tourRequest's agency is cleared
  //  - when the textbox loses focus (onBlur), we resync the below with the tourRequest values
  const [agencyInputText, setAgencyInputText] = useState<string>()
  const [agencySelectedItems, setAgencySelectedItems] = useState<{ id: string, name: string }[]>()

  const [countryInputText, setCountryInputText] = useState<string>()
  const [countrySelectedItems, setCountrySelectedItems] = useState<{ name: string, label: string }[]>()

  const [dateinputTourStartDate, setDateinputTourStartDate] = useState<Date | null>(null)
  const [dateinputTourEndDate, setDateinputTourEndDate] = useState<Date | null>(null)

  const agencyHash = useMemo(() => {
    if (!simpleAgencyList)
      return undefined
    return new Map<string, AgencySimpleType>(simpleAgencyList.map((a) => [a.twoCharacterCode, a]))
  }, [simpleAgencyList])


  // **** all hooks above this line


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['tour request', tourRequest],
    ['request classification lists', requestClassificationLists],
    ['country list', countryList],
    ['agency list', simpleAgencyList && agencyHash],
    ['user list', userListSimple],
  ])
  if (!tourRequest || !requestClassificationLists || !countryList || !simpleAgencyList || !agencyHash || !userListSimple)
    return loadingSpinner


  const { accommodationPreferences, customerTypes, eightyDaysTeams, guidePreferences, accommodationStars } = requestClassificationLists


  const tourNumDays = calcTourNumDaysIso(tourRequest.dateisoTourStart, tourRequest.dateisoTourEnd)



  const saveNewTourRequestToDb = () => {
    if (action !== 'create') {
      // should never happen
      throw new Error('saveNewTourRequestToDb called in edit mode')
    }

    setInfo('Saving...')

    if (!tourRequest.eightyDaysDepartment) {
      if (tourRequest.dateCreated > new Date('2023-08-12')) {
        setErr('Team/category missing')
        return
      }
    }

    if (!tourRequest.customerType) {
      setErr('Customer type missing')
      return
    }

    if (!tourRequest.requestCode) {
      setErr('Request code missing')
      return
    }

    const requestCode = tourRequest.requestCode.trim()

    if (!tourRequest.country && action !== 'create') {
      // Typeahead will not provide a value if user did not click the item in the list,
      // so we catch that case here

      // Actually, none of the other fields are required, so for consistency we don't require this one either.
      // The only potential issue is the user typing in a country name but not clicking on an item in the list.
      // In that case, nothing will be saved.
      //setErr('Country missing')
      //return
    }

    if (tourRequest.numOfPax && typeof tourRequest.numOfPax !== 'number') {
      setErr('Num of travellers must be a number')
      return
    }

    const dbObj: any = {
      ...tourRequest,
      requestCode,
    }

    delete dbObj.id

    const checkDupesAndSave = async () => {
      // check requestCode not used just before adding, to ensure no duplicates
      const snap_dupe_requestCode = await getCountFromServer(query(collection(db, 'tourrequests'), where('requestCode', '==', dbObj.requestCode)))
      if (snap_dupe_requestCode.data().count > 0) {
        setErr(`Request code ${dbObj.requestCode} is already used.`)
        return
      }

      // get request number based on current max in database
      dbObj.requestNumber = await getNextRequestNumber(db)

      const addedDoc = await addDoc(collection(db, 'tourrequests'), dbObj)

      setSuccessSaved()
      console.log(`Created new request with id ${addedDoc.id}`)
      log_db_write({ db, userDetails, logkey: 'db_write.tourrequest.save_new', desc: `Saved NEW tour request to db [${tourRequest.requestCode}] [${addedDoc.id}]` })
      navigate(`/requests/edit/${addedDoc.id}`)
    }

    checkDupesAndSave()
      .catch((err) => setDbError('Adding new tour request', err))
  }

  const disableRequestCode = tourRequest.requestCode && action === 'edit'

  const fourFieldsForRequestCodePresent =
    tourRequest.dateOriginallyReceived
    && tourRequest.eightyDaysDepartment
    && tourRequest.customerType
    && tourRequest.agencyOrPlatformId


  const validateTwoCharacterCodeAndCheckDupes = async (inputStr: string) => {
    // eslint bug: see https://github.com/eslint/eslint/issues/10401 or https://github.com/eslint/eslint/issues/3223
    // eslint-disable-next-line prefer-const
    let { str, err } = validateTwoCharacterCode(inputStr)

    // check for dupes
    if (!err) {
      const snapshot = await getDocs(query(collection(db, 'agencies'), where('twoCharacterCode', '==', str)))
      if (snapshot.docs.length > 0) {
        err = `Code ${str} is already used by another agency: ${snapshot.docs[0].data().name}`
      }
    }

    return { str, err }
  }



  const autosaveNewStep = async (
    userAction: string,
    updateObj: Partial<TourRequestType>,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {

    // caches are refreshed directly when editing si2. we can't recompute caches here, because current tourrequest object does not reflect user's latest changes.

    return autosaveAnyStep(userAction, updateObj, false, undefined, sUndoWall)
  }

  const autosaveAnyStep = async (
    userAction: string,
    updateObj: Partial<TourRequestType>,
    isUndoRedo: boolean,
    undoRedoTargetStep: number | undefined,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {

    if (!enableEditing) {
      setDbError('Field change despite editing being disabled')
      throw new Error('Field change despite editing being disabled')
    }

    return autosaveDocument(
      updateObj,
      userAction,
      isUndoRedo,
      undoRedoTargetStep,
      sUndoWall,
      tourRequest.id,
      tourRequest.history,
      userSimple,
      db,
      'tourrequests',
      (updateObj) => addMetadataModifiedTourRequest(updateObj, userDetails),
      setSaveStatus,
    )
      .catch((err) => setDbError(`Autosave tourrequest id=${tourrequestId} action=[${userAction}]`, err))
  }

  const autosaveUndoRedoStep = async (action: 'Undo' | 'Redo', targetStep: number) => {

    const undoRedoData = await getUndoRedoHistoryChanges(action, targetStep, tourRequest.history)
    if (!undoRedoData)
      // failed to retrieve history step from db
      return

    const { updateObjHistory, targetStepObj } = undoRedoData

    // fields that we remove in general:
    //   - id: we never save id as a field
    //   - _isDeleted: should always be false
    //   - history: specifically tweaked in a precise way by updateObjHistory
    //   - dateCreated, userCreated: immutable metadata
    //   - dateModified, userModified: metadata set upon save
    // tourrequest fields that we remove:
    //   - calendarDays

    // delete the field we specifically don't want:
    delete targetStepObj.id
    delete targetStepObj._isDeleted
    delete targetStepObj.history
    // @ts-expect-error field parentDocumentId doesn't exist on type ExpenseSheetType
    delete targetStepObj.parentDocumentId

    delete targetStepObj.dateCreated
    delete targetStepObj.userCreatedEmail
    delete targetStepObj.userCreatedName
    delete targetStepObj.userCreatedUid

    delete targetStepObj.dateModified
    delete targetStepObj.userModifiedEmail
    delete targetStepObj.userModifiedName
    delete targetStepObj.userModifiedUid

    // @ts-expect-error field metadataModified doesn't exist on type ExpenseSheetType
    delete targetStepObj.metadataModified // added incorrectly by the autosaveDocument function

    // add more immutable fields as needed:
    // here we should basically add all fields that are not editable through the CRUD UI
    delete targetStepObj.calendarDays
    delete targetStepObj.calendarColorMain
    delete targetStepObj.exploreSeriesCode
    delete targetStepObj.hiddenInCalendar
    delete targetStepObj.isExploreSeries
    delete targetStepObj.kintoneRecordNumber
    delete targetStepObj.salesInformation
    delete targetStepObj.status


    const updateObj: Partial<TourRequestType> = {
      ...targetStepObj,
      ...updateObjHistory,
    }

    autosaveAnyStep(
      action, // this isn't actually used
      updateObj,
      true,
      targetStep,
      'u', // undo/redo step is always undoable
    )
  }



  const typeahead_tailwind =
    `[&_input.rbt-input-main]:tw-bg-transparent
    disabled:[&_input.rbt-input-main]:tw-bg-transparent
    focus:[&_input.rbt-input-main]:tw-bg-white
    ${enableEditing ? '[&_input.rbt-input-main]:tw-border-blue-300' : '[&_input.rbt-input-main]:tw-border-slate-300'}`

  const datepicker_tailwind =
    `!tw-bg-transparent
    disabled:!tw-bg-transparent
    focus:!tw-bg-white
    ${enableEditing ? '!tw-border-blue-300' : '!tw-border-slate-300'}`


  const trySetNewMnemonic = (newMnemonic: string) => {
    validateTwoCharacterCodeAndCheckDupes(newMnemonic)
      .then(({ str, err }) => {
        setModalAction({
          ...modalAction!,
          newAgencyMnemonic: str,
          newAgencyMnemonicError: err,
        })
      })
  }


  return (
    <div>
      <Helmet><title>{action === 'create' ? 'Create request' : `Edit request ${tourRequest?.requestCode}`}</title></Helmet>

      <TopWhiteBarEditControls
        whiteBarActive={action === 'edit'}
        enableEditing={enableEditing}
        setEnableEditing={setEnableEditing}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
        autosaveUndoRedoStep={autosaveUndoRedoStep}
        history={tourRequest.history}
        divFloatingTotals={null}
        userIsAllowedToEdit={userIsAllowedToEdit}
      />

      <div className='container-md'>

        <h2 className='mt-5' style={{ display: 'flex', justifyContent: 'space-between' }}>
          {action === 'create' ? (
            <>Create tour request</>
          ) : (
            <>
              <div>Edit tour request</div>
              <div>
                {tourRequest.requestCode}
              </div>
            </>
          )}
        </h2>

        {action !== 'create' && (
          <div>
            <RequestCodeLinkToAggregator
              requestCode={tourRequest.requestCode}
              linkId='tourrequestcrud'
              shownPopup={shownPopup}
              setShownPopup={setShownPopup}
            />
          </div>
        )}

        <>

          <div className='maingrid'>

            <hr />

            <div className='col1'></div>
            <div className='col2'>
              <h5 style={{ fontSize: '1.25em' }}>Categorisation information</h5>
              <div>Request Code is based in part on information from this section. Please input the information in this section before generating the Request Code.</div>
            </div>


            <div className='col1'>Date received:</div>
            <div className='col2 info-tooltip-container'>
              <div>
                <DateInput
                  className={datepicker_tailwind}
                  disabled={!enableEditing}
                  value_local0={tourRequest.dateOriginallyReceived ? local0_from_iso(tourRequest.dateOriginallyReceived) : null}
                  onChange={(date_local0) => {
                    let date_utc
                    if (!date_local0) {
                      // empty date??
                      date_utc = getTodayUTC()
                    } else if (date_local0 <= new Date()) {
                      date_utc = utc0_from_local0(date_local0)
                    } else {
                      window.alert('Date received cannot be in the future')
                      date_utc = getTodayUTC()
                    }
                    const date_str = iso_from_utc0(date_utc)
                    console.log('date local', date_local0)
                    console.log('date utc', date_utc)
                    console.log('date str', date_str)
                    if (action === 'create') {
                      setTourRequest({ ...tourRequest, dateOriginallyReceived: date_str })
                    } else {
                      autosaveNewStep(`Change date received to ‘${dateutcFormatJpShort(date_utc)}’`, {
                        dateOriginallyReceived: date_str,
                      }, 'u')
                    }
                  }}
                />
              </div>
              <InfoToolTip
                tooltipId='tourrequest-crud-tooltip'
                tooltipHtml='Date that the request was originally received from the customer'
                tooltipPlace='bottom'
              />
            </div>

            <div className='col1'>Travel designers:</div>
            <div className='col2'>
              <div>
                <TypeaheadUserList
                  id='inputPersonInCharge'
                  multiple={true}
                  onChange={(array: UserSimpleTeamType[]) => {
                    const usersDesigners: UserSimpleUidType[] = []
                    const usersDesignersUids: string[] = []

                    for (const user of array) {
                      if (usersDesigners.some((u) => u.uid === user.id))
                        // don't allow duplicates
                        continue
                      const newUser: UserSimpleUidType = {
                        uid: user.id,
                        email: user.email,
                        name: user.name,
                      }
                      usersDesigners.push(newUser)
                      usersDesignersUids.push(user.id)
                    }

                    if (action === 'create') {
                      setTourRequest({ ...tourRequest, usersDesigners, usersDesignersUids })
                    } else {
                      const updateObj: Partial<TourRequestType> = {
                        usersDesigners,
                        usersDesignersUids,
                      }
                      autosaveNewStep(`Set travel designers to ‘${array.map((u) => u.name).join(', ')}’`, updateObj, 'u')
                    }
                  }}
                  userList={userListSimple}
                  selected={tourRequest.usersDesigners.map((user) => {
                    const dbUser = userListSimple.find((u) => u.id === user.uid)
                    const newUser: UserSimpleTeamType = {
                      id: user.uid,
                      email: user.email,
                      name: user.name,
                      teamName: dbUser?.teamName || '',
                    }
                    return newUser
                  })}
                  guidesFirst={false}
                  disabled={!enableEditing}
                />
              </div>

            </div>

            <div className='col1'>Team/category:</div>
            <div className='col2'>
              <table className='tableTeam'>
                <tbody>
                  {eightyDaysTeams.map((group) => {
                    return (
                      <tr key={group.name}>
                        <td className='teamGroupName'>{group.name}</td>
                        <td>
                          {group.items.map((team) => {
                            const id = `team_${sanitizeId(team)}`
                            return (
                              <Form.Check type='radio' inline key={id} id={id} name='eightyDaysTeam' label={<TeamName name={team} />}
                                checked={tourRequest.eightyDaysDepartment === team}
                                disabled={!enableEditing}
                                onChange={(e) => {
                                  if (!e.target.checked)
                                    return

                                  if (action === 'create') {
                                    setTourRequest({ ...tourRequest, eightyDaysDepartment: team })
                                  } else {
                                    autosaveNewStep(`Change team/category to ‘${team}’`, {
                                      eightyDaysDepartment: team,
                                    }, 'u')
                                  }
                                }}
                              />
                            )
                          })}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <div className='col1'>Customer type:</div>
            <div className='col2'>
              {customerTypes.map((customerType) => {
                const id = `customerType_${sanitizeId(customerType)}`
                return (
                  <Form.Check type='radio' inline key={id} id={id} name='customerType' label={customerType}
                    disabled={!enableEditing}
                    checked={tourRequest.customerType === customerType}
                    onChange={(e) => {
                      if (!e.target.checked)
                        return

                      if (action === 'create') {
                        setTourRequest({ ...tourRequest, customerType })
                      } else {
                        autosaveNewStep(`Change customer type to ‘${customerType}’`, {
                          customerType,
                        }, 'u')
                      }
                    }}
                  />
                )
              })}
            </div>

            <div className='col1'>Agency/Platform:</div>
            <div className='col2' style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>

              <div style={{ width: '30em' }}>
                <Typeahead
                  className={`typeahead-suppress-popper-warning ${typeahead_tailwind}`}
                  id='tourrequestAgencyOrPlatform'
                  labelKey='name'
                  options={simpleAgencyList}
                  minLength={2}
                  disabled={!enableEditing}
                  allowNew={true}
                  newSelectionPrefix='Add new: '
                  selected={
                    //tourRequest.agencyOrPlatform ? [{ id: tourRequest.agencyOrPlatformId, name: tourRequest.agencyOrPlatform }] : []
                    agencySelectedItems
                  }
                  inputProps={{ style: { color: tourRequest.agencyOrPlatform === agencyInputText ? '' : 'darkgray' } }}
                  filterBy={['name']}
                  onInputChange={(text, event) => {
                    console.log('INPUT CHANGE', text, event)
                    setAgencyInputText(text)
                    if (text === '' && tourRequest.agencyOrPlatformId) {
                      // if user has completely cleared the textbox, we clear the agency on the tourRequest
                      if (action === 'create') {
                        setTourRequest({ ...tourRequest, agencyOrPlatform: '', agencyOrPlatformId: '' })
                      } else {
                        autosaveNewStep('Clear agency/platform', {
                          agencyOrPlatform: '',
                          agencyOrPlatformId: '',
                        }, 'u')
                      }
                    }
                  }}
                  onBlur={() => {
                    // if user started editing the text in the textbox, restore it to the current value
                    resetAgency(tourRequest)
                  }}
                  // @ts-expect-error Typeahead
                  onChange={(array: (AgencySimpleType & { customOption: boolean })[]) => {
                    console.log('SELECTION CHANGE agency typeahead onchange', array)
                    setAgencySelectedItems(array)
                    if (array.length === 0) {
                      // clearing the agency on the tourrequest is handled in onInputChange, because we only want to clear
                      // the agency on the tourrequest if the textbox is completely cleared
                      return
                    }

                    const value = array[0]
                    setAgencyInputText(value.name) // when this handler (onChange) is called due to user clicking on a menu item, onInputChange is NOT called. (exception: if user deletes all the text in one go, both onInputChange and onChange are called, in that order)

                    if (value.customOption) {
                      // we try automatically suggesting the first 2 letters of the agency name as mnemonic,
                      // but we check here whether it is already used or not, and if so, display the
                      // corresponding error
                      const newAgencyName = value.name.trim() // important to trim here
                      validateTwoCharacterCodeAndCheckDupes(newAgencyName.substring(0, 2))
                        .then(({ str, err }) => {
                          setModalAction({
                            action: 'add_agency',
                            newAgencyName,
                            newAgencyMnemonic: str,
                            newAgencyMnemonicError: err,
                          })
                        })
                    } else if (value.name) {
                      if (action === 'create') {
                        setTourRequest({ ...tourRequest, agencyOrPlatform: value.name, agencyOrPlatformId: value.id })
                      } else {
                        autosaveNewStep(`Set agency/platform to ‘${value.name}’`, {
                          agencyOrPlatform: value.name,
                          agencyOrPlatformId: value.id,
                        }, 'u')
                      }
                      console.log(`selected agency [${value.name}] id=${value.id}`)
                    } else {
                      console.log(value)
                    }
                  }}
                />
              </div>

              {tourRequest.agencyOrPlatformId && userrole_canEditAnyInvoice(userDetails.roles) && (
                <div>
                  <Link to={`/clients/edit/${tourRequest.agencyOrPlatformId}`}>Edit agency</Link>
                </div>
              )}

              <div>
                For direct customers, select ‘Direct’.
              </div>

            </div>

            {/* {['Direct', 'Referral'].includes(tourRequest.agencyOrPlatform) && ( */}
            <>
              <div className='col1'>Referral name (if applicable):</div>
              <div className='col2' style={{
                //  display: 'flex', gap: '1em', alignItems: 'center',
              }}>

                <EditableFieldTourRequest
                  tableid='tourrequest'
                  rowid='main'
                  fieldname='referralName'
                  validationType=''
                  currentValue={tourRequest.referralName || ''}
                  isClickableToEdit={enableEditing}
                  editedCell={editedCell}
                  setEditedCell={setEditedCell}
                  callbackCommitChange={(value) => {
                    if (action === 'create') {
                      setTourRequest({ ...tourRequest, referralName: value })
                    } else {
                      autosaveNewStep(`Change referral name to ‘${value}’`, {
                        referralName: value,
                      }, 'u')
                    }
                    setEditedCell(null)
                  }}
                />

              </div>
            </>
            {/* )} */}

            <hr />

            <div className='col1'>Request code:</div>
            <div className='d-flex' style={{ gap: '1em', alignItems: 'center' }}>
              {!disableRequestCode && (
                <div>
                  <ButtonTW
                    variant={fourFieldsForRequestCodePresent ? 'blue' : 'darkgray_outline'}
                    onClick={(e) => {
                      if (action !== 'create') {
                        // should never happen
                        return
                      }

                      setStatusMsg('')
                      setIsGeneratingRequestCode(true)

                      const generateLuhnCode = async () => {
                        // first check user input the 4 required fields: date received, team/category, customer type, agency/platform
                        if (!tourRequest.dateOriginallyReceived) {
                          setErr('Please input the date this request was originally received.')
                          return
                        }

                        const { firstLetter, err: firstLetterErr } = getCodeLetter(tourRequest)
                        if (firstLetterErr) {
                          setErr(firstLetterErr)
                          return
                        }

                        if (!tourRequest.agencyOrPlatformId) {
                          setErr('Please input the agency/platform')
                          return
                        }

                        // next, do some more precise validation
                        const date_utc = utc0_from_iso(tourRequest.dateOriginallyReceived)

                        const fullYear = date_utc.getUTCFullYear()
                        const month = date_utc.getUTCMonth() + 1
                        if (fullYear < 2020 || fullYear > 2040) {
                          setErr(`Year ${fullYear} is not valid`)
                          return
                        }
                        const yearChar = String(fullYear).slice(-1)
                        let sMonth = `${month}`
                        if (month === 10) sMonth = 'X'
                        else if (month === 11) sMonth = 'Y'
                        else if (month === 12) sMonth = 'Z'

                        let letterUser = userDetails.singleCharacterCode
                        if (!letterUser)
                          letterUser = '9'

                        const docu = await getDoc(doc(db, 'agencies', tourRequest.agencyOrPlatformId))

                        let agencyCode = docu.data()?.twoCharacterCode
                        if (!agencyCode)
                          agencyCode = '98'


                        let validCode = null

                        // loop to repeat random number generation until we get a unique code. max 30 tries
                        for (let i = 0; i < 30; i++) {

                          const randomInt = getRandomInt(34)
                          const randomChar = luhnCodePoints[randomInt]

                          const code = `${firstLetter}${yearChar}${sMonth}${letterUser}${agencyCode}${randomChar}`

                          // validate string
                          if (code.length !== 7)
                            throw new Error(`wrong string length: ${code.length}`)
                          for (const c of code) {
                            if (!luhnCodePoints.includes(c))
                              throw new Error(`invalid character ${c}`)
                          }

                          const checkChar = generateCheckCharacter(code)
                          const fullCode = `${code}${checkChar}`
                          const checkResult = validateCheckCharacter(fullCode)
                          if (!checkResult)
                            throw new Error('check character validation failed')

                          const formattedCode = `${fullCode.substring(0, 4)}-${fullCode.substring(4)}`


                          const q = query(collection(db, 'tourrequests'), where('requestCode', '==', formattedCode)) // we also check deleted ones...
                          const snapshot = await getCountFromServer(q)
                          const count = snapshot.data().count
                          if (count === 0) {
                            validCode = formattedCode
                            break
                          }
                        } // loop: try again

                        if (!validCode) {
                          setErr('Failed to find a unique code after 30 tries')
                          return
                        }

                        return validCode
                      }

                      generateLuhnCode()
                        .then((formattedCode) => {

                          if (!formattedCode) {
                            // code generation failed. alert was already dispayed to user explaining the reason
                            setTourRequest({ ...tourRequest, requestCode: '' })
                          } else {
                            setTourRequest({ ...tourRequest, requestCode: formattedCode })
                          }
                          setIsGeneratingRequestCode(false)
                        })

                    }}>generate</ButtonTW>
                </div>
              )}

              <div style={{ width: '10em', opacity: isGeneratingRequestCode ? 0.5 : undefined }}>
                <EditableFieldTourRequest
                  tableid='tourrequest'
                  rowid='main'
                  fieldname='requestCode'
                  validationType=''
                  currentValue={tourRequest.requestCode}
                  isClickableToEdit={!disableRequestCode}
                  editedCell={editedCell}
                  setEditedCell={setEditedCell}
                  callbackCommitChange={(value) => {

                    // if user inputs a code manually in the format XXXX-XXXX,
                    // we check the Luhn check character is valid,
                    // and if not, we generate a valid one a force replace the input.
                    let manualCode = value
                    if (manualCode.match(/^[A-Z0-9]{4}-[A-Z0-9]{4}$/i)) {
                      manualCode = normalizeForLuhn(manualCode)
                      const codeNoDash = manualCode.replace(/-/g, '')
                      if (!validateCheckCharacter(codeNoDash)) {
                        const checkChar = generateCheckCharacter(codeNoDash.substring(0, 7))
                        manualCode = `${codeNoDash.substring(0, 4)}-${codeNoDash.substring(4, 7)}${checkChar}`
                      }
                    }

                    if (action === 'create') {
                      setTourRequest({ ...tourRequest, requestCode: manualCode })
                    }

                    setEditedCell(null)
                  }}
                />
              </div>

              <div>
                <a href='/documents/Eighty_Days_Request_Code_Breakdown.pdf' target='_blank' className='tw-no-underline tw-whitespace-nowrap'>
                  <i className='bi bi-question-circle'></i>
                  {' '}
                  Help
                </a>
              </div>
            </div>
            <div></div>
            <div className='' style={{ display: 'flex', gap: '1.5em', alignItems: 'center' }}>
              {tourRequest.requestNumber && (
                <>
                  Sequence number:
                  <Form.Control type='text' id='tourrequestNumber' style={{ width: '5em' }} className='numeric'
                    value={tourRequest.requestNumber || ''} // 0 displayed as '' because Number('') gets stored as 0 in tourRequest
                    disabled={true}
                  />
                </>
              )}
            </div>

            <hr />

            {action === 'create' ? (
              <>
                <div className='col1'></div>
                <div className='col2'>
                  <ButtonTW
                    textSize='md'
                    variant={(fourFieldsForRequestCodePresent && tourRequest.requestCode) ? 'blue' : 'darkgray_outline'}
                    onClick={(e) => saveNewTourRequestToDb()}
                  >Create new request</ButtonTW>
                </div>

                <div className='col1'></div>
                <div className='col2'>
                  {statusMsg}
                </div>

              </>
            ) : (
              <>

                <div className='col1'>Country:</div>
                <div className='col2'>

                  <Typeahead
                    className={`typeahead-suppress-popper-warning ${typeahead_tailwind}`}
                    id='tourrequestCountry'
                    options={countryList}
                    allowNew={true}
                    newSelectionPrefix='Other: '
                    selected={countrySelectedItems}
                    inputProps={{ style: { color: countryList.find((c) => c.name === tourRequest.country)?.label === countryInputText ? '' : 'silver' } }}
                    filterBy={['label']}
                    disabled={!enableEditing}
                    onInputChange={(text, event) => {
                      console.log('COUNTRY INPUT CHANGE', text)
                      setCountryInputText(text)
                      if (text === '' && tourRequest.country) {
                        autosaveNewStep('Clear country', {
                          country: '',
                        }, 'u')
                      }
                    }}
                    onBlur={() => {
                      resetCountry(tourRequest, countryList)
                    }}
                    // @ts-expect-error Typeahead
                    onChange={(array: (CountryListItemType & { customOption: boolean })[]) => {
                      console.log('COUNTRY ONCHANGE', array)
                      setCountrySelectedItems(array)
                      if (array.length === 0) {
                        return
                      }

                      const value = array[0]
                      let countryName
                      if (value.name) {
                        countryName = value.name
                      } else if (value.customOption) {
                        countryName = value.label
                      } else {
                        console.error('Country name is empty')
                      }
                      if (countryName) {
                        autosaveNewStep(`Set country to ‘${countryName}’`, {
                          country: countryName,
                        }, 'u')
                      }
                    }}
                  />

                </div>

                <div className='col1'>State/region:</div>
                <div className='col2'>
                  <EditableFieldTourRequest
                    tableid='tourrequest'
                    rowid='main'
                    fieldname='stateOrRegion'
                    validationType=''
                    currentValue={tourRequest.stateOrRegion}
                    isClickableToEdit={enableEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(value) => {
                      autosaveNewStep(`Change state/region to ‘${value}’`, {
                        stateOrRegion: value,
                      }, 'u')
                      setEditedCell(null)
                    }}
                  />
                </div>

                <div className='col1'>Tour dates:</div>
                <div className='col2 d-flex tourDates'>
                  <DateRangeInput
                    className={datepicker_tailwind}
                    startDate={dateinputTourStartDate}
                    endDate={dateinputTourEndDate}
                    onChange={(dates) => {
                      console.log('ONCHANGE', dates)
                      const [tourStartLocal, tourEndLocal] = dates
                      setDateinputTourStartDate(tourStartLocal)
                      setDateinputTourEndDate(tourEndLocal)

                      if (tourStartLocal && tourEndLocal) {
                        const tourStartUtc0 = utc0_from_local0(tourStartLocal)
                        const tourEndUtc0 = utc0_from_local0(tourEndLocal)
                        const dateisoTourStart = iso_from_utc0(tourStartUtc0)
                        const dateisoTourEnd = iso_from_utc0(tourEndUtc0)
                        autosaveNewStep(`Change tour dates to ‘${dateutcFormatJpShort(tourStartUtc0)} - ${dateutcFormatJpShort(tourEndUtc0)}’`, {
                          dateisoTourStart,
                          dateisoTourEnd,
                        }, 'u')
                      } else if (!tourStartLocal && !tourEndLocal) {
                        autosaveNewStep('Clear tour dates', {
                          dateisoTourStart: '',
                          dateisoTourEnd: '',
                        }, 'u')
                      }
                    }}
                    onBlur={() => {
                      resetTourDates(tourRequest)
                    }}
                    disabled={!enableEditing}
                  />
                  <div className='ms-3 pt-2'>{tourNumDays ? `(${tourNumDays} ${tourNumDays === 1 ? 'day' : 'days'})` : ''}</div>
                  <div className='tw-ms-3 tw-pt-3 tw-text-sm'>(for a 1 day tour, click the same date twice in the calendar)</div>
                </div>

                <div className='col1'>Traveller name:</div>
                <div className='col2'>
                  <EditableFieldTourRequest
                    tableid='tourrequest'
                    rowid='main'
                    fieldname='travellerName'
                    validationType=''
                    currentValue={tourRequest.travellerName}
                    isClickableToEdit={enableEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(value) => {
                      autosaveNewStep(`Change pax name to ‘${value}’`, {
                        travellerName: value,
                      }, 'u')
                      setEditedCell(null)
                    }}
                  />
                </div>

                <div className='col1'>Num of travellers:</div>
                <div className='col2'>
                  <EditableFieldTourRequest
                    tableid='tourrequest'
                    rowid='main'
                    fieldname='numOfPax'
                    validationType='number'
                    currentValue={tourRequest.numOfPax ?? ''}
                    isClickableToEdit={enableEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(value) => {
                      const dbValue = value === '' ? null : value // either null (empty field) or a number. never a string
                      autosaveNewStep(`Change num of pax to ‘${value}’`, {
                        numOfPax: dbValue,
                      }, 'u')
                      setEditedCell(null)
                    }}
                  />
                </div>

                <div className='col1'>Special occasion</div>
                <div className='col2'>
                  <EditableFieldTourRequest
                    tableid='tourrequest'
                    rowid='main'
                    fieldname='specialOccasion'
                    validationType=''
                    currentValue={tourRequest.specialOccasion}
                    isClickableToEdit={enableEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(value) => {
                      autosaveNewStep(`Change special occasion to ‘${value}’`, {
                        specialOccasion: value,
                      }, 'u')
                      setEditedCell(null)
                    }}
                    isTextArea={true}
                    textareaRows={3}
                  />
                </div>

                <div className='col1'>Guide preference:</div>
                <div className='col2'>
                  {guidePreferences.map((guidePref) => {
                    const id = `guidePref_${sanitizeId(guidePref)}`
                    return (
                      <Form.Check type='radio' inline key={id} id={id} name='guidePref' label={guidePref}
                        checked={tourRequest.guidePreference === guidePref}
                        disabled={!enableEditing}
                        onChange={(e) => {
                          if (!e.target.checked)
                            return

                          autosaveNewStep(`Change guide preference to ‘${guidePref}’`, {
                            guidePreference: guidePref,
                          }, 'u')
                        }}
                      />
                    )
                  })}
                  <ButtonTW variant='link'
                    disabled={!enableEditing}
                    onClick={(e) => {
                      autosaveNewStep('Clear guide preference', {
                        guidePreference: '',
                      }, 'u')
                    }}><i className='bi bi-x-square'></i></ButtonTW>
                </div>

                <div className='col1'>Guide language:</div>
                <div className='col2'>
                  <EditableFieldTourRequest
                    tableid='tourrequest'
                    rowid='main'
                    fieldname='guideLanguage'
                    validationType=''
                    currentValue={tourRequest.guideLanguage}
                    isClickableToEdit={enableEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(value) => {
                      autosaveNewStep(`Change guide language to ‘${value}’`, {
                        guideLanguage: value,
                      }, 'u')
                      setEditedCell(null)
                    }}
                  />
                </div>

                <div className='col1'>Accommodation type:</div>
                <div className='col2'>
                  {accommodationStars.map((stars) => {
                    const id = `accommodationStars_${sanitizeId(stars)}`
                    return (
                      <Form.Check type='checkbox' inline key={id} id={id} name='accommodationStars' label={stars}
                        checked={tourRequest.accommodationStars.includes(stars)}
                        disabled={!enableEditing}
                        onChange={(e) => {
                          const accommodationStarsNew =
                            e.target.checked
                              ? [...tourRequest.accommodationStars, stars] // TODO: check for duplicate values
                              : tourRequest.accommodationStars.filter((x) => x !== stars)
                          accommodationStarsNew.sort()
                          autosaveNewStep(`Change accommodation type to ‘${accommodationStarsNew.join(', ')}’`, {
                            accommodationStars: accommodationStarsNew,
                          }, 'u')
                        }}
                      />
                    )
                  })}
                </div>

                <div className='col1'>Accommodation preference:</div>
                <div className='col2'>
                  {accommodationPreferences.map((pref) => {
                    const id = `accommodationPref_${sanitizeId(pref)}`
                    return (
                      <Form.Check type='checkbox' inline key={id} id={id} name='accommodationPref' label={pref}
                        checked={tourRequest.accommodationPreference.includes(pref)}
                        disabled={!enableEditing}
                        onChange={(e) => {
                          const accommodationPreferenceNew =
                            e.target.checked
                              ? [...tourRequest.accommodationPreference, pref] // TODO: check for duplicate values
                              : tourRequest.accommodationPreference.filter((x) => x !== pref)
                          autosaveNewStep(`Change accommodation preference to ‘${accommodationPreferenceNew.join(', ')}’`, {
                            accommodationPreference: accommodationPreferenceNew,
                          }, 'u')
                        }}
                      />
                    )
                  })}
                </div>

                <div className='col1'>Dietary requirements</div>
                <div className='col2'>
                  <EditableFieldTourRequest
                    tableid='tourrequest'
                    rowid='main'
                    fieldname='dietaryRequirements'
                    validationType=''
                    currentValue={tourRequest.dietaryRequirements}
                    isClickableToEdit={enableEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(value) => {
                      autosaveNewStep(`Change dietary requirements to ‘${value}’`, {
                        dietaryRequirements: value,
                      }, 'u')
                      setEditedCell(null)
                    }}
                    isTextArea={true}
                    textareaRows={3}
                  />
                </div>

                <div className='col1'>Customer’s interests</div>
                <div className='col2'>
                  <EditableFieldTourRequest
                    tableid='tourrequest'
                    rowid='main'
                    fieldname='customersInterests'
                    validationType=''
                    currentValue={tourRequest.customersInterests}
                    isClickableToEdit={enableEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(value) => {
                      autosaveNewStep(`Change customer’s interests to ‘${value}’`, {
                        customersInterests: value,
                      }, 'u')
                      setEditedCell(null)
                    }}
                    isTextArea={true}
                    textareaRows={3}
                  />
                </div>

                <div className='col1'>Trip comments</div>
                <div className='col2'>
                  <EditableFieldTourRequest
                    tableid='tourrequest'
                    rowid='main'
                    fieldname='requestComments'
                    validationType=''
                    currentValue={tourRequest.requestComments}
                    isClickableToEdit={enableEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(value) => {
                      autosaveNewStep(`Change trip comments to ‘${value}’`, {
                        requestComments: value,
                      }, 'u')
                      setEditedCell(null)
                    }}
                    isTextArea={true}
                    textareaRows={3}
                  />
                </div>

                <h5>Updated information</h5>

                <div className='col1'>Status</div>
                <div className='col2'>
                  <div className='tw-mb-3'>
                    {enableEditing ? (
                      <RequestStatusPillEditor
                        selectedPill={getSelectedPill(tourRequest)}
                        vertical={false}
                        clickHandler={(clicked: PillListType) => {
                          const { userAction, updateObj } = saveTourStatusToDb_getUpdateObj(clicked)

                          autosaveNewStep(userAction, updateObj, 'UNDOWALL') // status change is NOT undoable
                        }}
                      />
                    ) : (
                      <RequestStatusPillCurrent
                        tourrequest={tourRequest}
                        vertical={false}
                        clickHandler={undefined}
                      />
                    )}
                  </div>
                  <div>
                    <Form.Check type='checkbox' inline id='statusReceivedDeposit' label='Received deposit'
                      checked={tourRequest.statusReceivedDeposit ?? false}
                      disabled={!enableEditing}
                      onChange={(e) => {
                        const value = e.target.checked
                        autosaveNewStep(`${value ? 'Tick' : 'Untick'} status ’Received Deposit‘`, {
                          statusReceivedDeposit: value,
                        }, 'u')
                      }}
                    />
                    <Form.Check type='checkbox' inline id='statusReceivedTotalTripFee' label='Received total trip fee'
                      checked={tourRequest.statusReceivedTotalTripFee ?? false}
                      disabled={!enableEditing}
                      onChange={(e) => {
                        const value = e.target.checked
                        autosaveNewStep(`${value ? 'Tick' : 'Untick'} status ’Received Total Trip Fee‘`, {
                          statusReceivedTotalTripFee: value,
                        }, 'u')
                      }}
                    />
                  </div>
                </div>

                <div className='col1'>Update comment</div>
                <div className='col2'>
                  <EditableFieldTourRequest
                    tableid='tourrequest'
                    rowid='main'
                    fieldname='updatedComment'
                    validationType=''
                    currentValue={tourRequest.updatedComment}
                    isClickableToEdit={enableEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(value) => {
                      autosaveNewStep(`Change update comment to ‘${value}’`, {
                        updatedComment: value,
                      }, 'u')
                      setEditedCell(null)
                    }}
                    isTextArea={true}
                    textareaRows={3}
                  />
                </div>

                <div className='col1'>Traveller’s information</div>
                <div className='col2'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>DOB</th>
                        <th>Age</th>
                        <th>Passport information received</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tourRequest.travellers.map((t, index) => {

                        return (
                          <tr key={index}>
                            <td>
                              <EditableField
                                tableid='travellers'
                                rowid={`row_${index}`}
                                fieldname='name'
                                validationType=''
                                currentValue={t.name}
                                isClickableToEdit={enableEditing}
                                editedCell={editedCell}
                                setEditedCell={setEditedCell}
                                callbackCommitChange={(value) => {
                                  autosaveNewStep(`Change traveller [${1 + index}] name to ‘${value}’`, {
                                    travellers: tourRequest.travellers.map((item, i) => {
                                      return i === index ? { ...item, name: value } : item
                                    }),
                                  }, 'u')
                                  setEditedCell(null)
                                }}
                              />
                            </td>
                            <td>
                              <EditableField
                                tableid='travellers'
                                rowid={`row_${index}`}
                                fieldname='dateOfBirth'
                                placeholderText='yyyy/mm/dd'
                                validationType=''
                                currentValue={t.dateOfBirth ?? ''}
                                isClickableToEdit={enableEditing}
                                editedCell={editedCell}
                                setEditedCell={setEditedCell}
                                callbackCommitChange={(value) => {
                                  autosaveNewStep(`Change traveller [${1 + index}] d.o.b to ‘${value}’`, {
                                    travellers: tourRequest.travellers.map((item, i) => {
                                      if (i === index) {
                                        const dateOfBirth = value
                                        console.log('**DOB', dateOfBirth)
                                        const datejst = tryParseDateToJst0(dateOfBirth)
                                        return {
                                          ...item,
                                          dateOfBirth,
                                          age: datejst ? `${getAge(datejst)}` : '',
                                        }
                                      } else {
                                        return item
                                      }
                                    }),
                                  }, 'u')
                                  setEditedCell(null)
                                }}
                                customValidator={(value: string) => {
                                  if (!value) {
                                    return [true, '', '']
                                  }
                                  const date = tryParseDateToJst0(value)
                                  if (!date)
                                    return [false, undefined, value]
                                  return [true, value, value]
                                }}
                              />
                            </td>
                            <td>
                              <EditableField
                                tableid='travellers'
                                rowid={`row_${index}`}
                                fieldname='age'
                                validationType=''
                                currentValue={t.age}
                                isClickableToEdit={enableEditing}
                                editedCell={editedCell}
                                setEditedCell={setEditedCell}
                                callbackCommitChange={(value) => {
                                  autosaveNewStep(`Change traveller [${1 + index}] age to ‘${value}’`, {
                                    travellers: tourRequest.travellers.map((item, i) => {
                                      return i === index ? { ...item, age: value } : item
                                    }),
                                  }, 'u')
                                  setEditedCell(null)
                                }}
                              />
                            </td>
                            <td>
                              <input type='checkbox' checked={t.passportInfoReceived}
                                disabled={!enableEditing}
                                onChange={(e) => {
                                  autosaveNewStep(`Change traveller [${1 + index}] passport received to ‘${e.target.checked ? 'yes' : 'no'}’`, {
                                    travellers: tourRequest.travellers.map((item, i) => {
                                      return i === index ? { ...item, passportInfoReceived: e.target.checked } : item
                                    }),
                                  }, 'u')
                                }} />
                            </td>
                            <td>
                              <DeleteButton
                                disabled={!enableEditing}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                  e.preventDefault()
                                  if (!window.confirm('Delete row?'))
                                    return

                                  autosaveNewStep(`Delete traveller [${1 + index}]`, {
                                    travellers: tourRequest.travellers.filter((item, i) => i !== index),
                                  }, 'u')
                                }} />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <ButtonTW variant='blue_outline'
                    disabled={!enableEditing}
                    onClick={(e) => {
                      const newTraveller: TravellerType = {
                        name: '',
                        dateOfBirth: '',
                        age: '',
                        passportInfoReceived: false,
                      }

                      autosaveNewStep('Add traveller', {
                        travellers: [...tourRequest.travellers, newTraveller],
                      }, 'u')
                    }}>Add traveller</ButtonTW>
                </div>

                <h5>Expedia bookings</h5>


                <div className='col1'>Expedia booking list</div>
                <div className='col2'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Code (14 chars)</th>
                        <th>Hotel name etc</th>
                        <th>Date paid</th>
                        <th>Amount paid</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(tourRequest.expediaBookings || []).map((t, index) => {

                        return (
                          <tr key={index}>
                            <td>
                              <EditableField
                                tableid='expediaBookings'
                                rowid={`row_${index}`}
                                fieldname='expediaCode'
                                validationType=''
                                currentValue={t.expediaCode}
                                isClickableToEdit={enableEditing}
                                editedCell={editedCell}
                                setEditedCell={setEditedCell}
                                callbackCommitChange={(value) => {
                                  autosaveNewStep(`Change expedia booking [${1 + index}] code to ‘${value}’`, {
                                    expediaBookings: tourRequest.expediaBookings!.map((item, i) => {
                                      return i === index ? { ...item, expediaCode: value } : item
                                    }),
                                  }, 'u')
                                  setEditedCell(null)
                                }}
                              />
                            </td>
                            <td>
                              <EditableField
                                tableid='expediaBookings'
                                rowid={`row_${index}`}
                                fieldname='hotelName'
                                validationType=''
                                currentValue={t.hotelName}
                                isClickableToEdit={enableEditing}
                                editedCell={editedCell}
                                setEditedCell={setEditedCell}
                                callbackCommitChange={(value) => {
                                  const updateObj: Partial<TourRequestType> = {
                                    expediaBookings: tourRequest.expediaBookings!.map((item, i) => {
                                      return i === index ? { ...item, hotelName: value } : item
                                    }),
                                  }

                                  autosaveNewStep(`Change expedia booking [${1 + index}] hotel name to ‘${value}’`, updateObj, 'u')
                                  setEditedCell(null)
                                }}
                              />
                            </td>
                            <td>
                              <EditableFieldDatepicker
                                currentValue_jst0={t.dateisoPaid ? jst0_from_iso(t.dateisoPaid) : null}
                                isClickableToEdit={enableEditing}
                                callbackCommitChange={(date_jst0) => {
                                  const dateiso = date_jst0 ? iso_from_jst0(date_jst0) : ''
                                  const updateObj: Partial<TourRequestType> = {
                                    expediaBookings: tourRequest.expediaBookings!.map((item, i) => {
                                      return i === index ? { ...item, dateisoPaid: dateiso } : item
                                    }),
                                  }

                                  autosaveNewStep(`Change expedia booking [${1 + index}] date paid name to ‘${dateiso}’`, updateObj, 'u')
                                  setEditedCell(null)
                                }}
                              />
                            </td>
                            <td>
                              <EditableField
                                tableid='expediaBookings'
                                rowid={`row_${index}`}
                                fieldname='expediaAmount'
                                validationType='number'
                                currentValue={t.expediaAmount ?? ''}
                                isClickableToEdit={enableEditing}
                                editedCell={editedCell}
                                setEditedCell={setEditedCell}
                                callbackCommitChange={(value) => {
                                  const updateObj: Partial<TourRequestType> = {
                                    expediaBookings: tourRequest.expediaBookings!.map((item, i) => {
                                      return i === index ? { ...item, expediaAmount: value } : item
                                    }),
                                  }

                                  autosaveNewStep(`Change expedia booking [${1 + index}] amount to ‘${value}’`, updateObj, 'u')
                                  setEditedCell(null)
                                }}
                              />
                            </td>
                            <td>
                              <DeleteButton
                                disabled={!enableEditing}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                  e.preventDefault()
                                  if (!window.confirm('Delete row?'))
                                    return

                                  const updateObj: Partial<TourRequestType> = {
                                    expediaBookings: tourRequest.expediaBookings!.filter((item, i) => i !== index),
                                  }

                                  autosaveNewStep(`Delete expedia booking [${1 + index}]`, updateObj, 'u')
                                }} />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <ButtonTW variant='blue_outline'
                    textSize='md'
                    disabled={!enableEditing}
                    onClick={(e) => {
                      const newExpediaBooking: ExpediaBookingType = {
                        expediaCode: '',
                        hotelName: '',
                        dateisoPaid: getTodayIso(),
                        expediaAmount: null,
                      }

                      const updateObj: Partial<TourRequestType> = {
                        expediaBookings: [...(tourRequest.expediaBookings || []), newExpediaBooking],
                      }

                      autosaveNewStep('Add expedia booking', updateObj, 'u')
                    }}>Add expedia booking</ButtonTW>
                </div>


                {userrole_isAdmin(userDetails.roles) && (
                  <>
                    <div className='col-all'>
                      <hr />
                      <h5>Quotation sheets</h5>
                    </div>

                    <div className='col1'></div>
                    <div className='col2'>
                      {!quotationSheetList ? (
                        <div>Loading...</div>
                      ) : (
                        <>
                          {!quotationSheetList.length ? (
                            <div className='mb-3'>This request does not currently have any quotation sheets.</div>
                          ) : (
                            <table className='table' style={{ width: 'auto' }}>
                              <colgroup>
                                <col style={{ width: '4em' }} />
                                <col style={{ width: '16em' }} />
                                <col style={{ width: '4em' }} />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Name</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {quotationSheetList.map((quotationsheet) => {
                                  return (
                                    <tr key={quotationsheet.id}>
                                      <td>
                                        <ButtonTW variant='blue_outline' to={`/quotations/edit/${quotationsheet.id}`}>Edit</ButtonTW>
                                      </td>
                                      <td>
                                        {quotationsheet.quotationSheetName}
                                      </td>
                                      <td>
                                        <ButtonTW variant='blue_outline' onClick={(e) => {
                                          if (!window.confirm('Are you sure you want to delete this quotation sheet?'))
                                            return

                                          const updateObj: Partial<QuotationType> = {
                                            _isDeleted: true,
                                          }

                                          autosaveDocument(
                                            updateObj,
                                            'DELETE',
                                            false,
                                            undefined,
                                            'UNDOWALL',
                                            quotationsheet.id,
                                            quotationsheet.history,
                                            getUserSimpleUid(userDetails),
                                            db,
                                            'quotationsheets',
                                            (updateObj) => addMetadataModifiedQuotations(updateObj, userDetails),
                                            null
                                          )
                                            .then(() => {
                                              console.log(`Deleted quotation sheet ${quotationsheet.id}`)
                                              log_db_write({ db, userDetails, logkey: 'db_write.quotation_sheet.delete', desc: `Deleted quotation sheet [${quotationsheet.id}]` })
                                            })
                                            .catch((err) => setDbError(`Deleting quotation sheet ${quotationsheet.id}`, err))
                                        }}>Delete</ButtonTW>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}

                          <ButtonTW
                            disabled={!enableEditing}
                            variant='blue_outline'
                            onClick={(e) => {
                              const validate = () => {
                                if (!tourRequest.requestCode)
                                  return 'No request code'
                                if (!tourRequest.dateisoTourStart)
                                  return 'No tour start date'
                                if (!tourRequest.dateisoTourEnd)
                                  return 'No tour end date'
                              }
                              const err = validate()
                              if (err) {
                                window.alert(err)
                                return
                              }

                              const quotationSheetName = window.prompt('Quotation sheet name', quotationSheetList.length ? '' : 'Main')
                              if (!quotationSheetName)
                                return

                              createNewQuotation(db, userDetails, tourRequest, tourrequestId!, quotationSheetName)
                                .then((result) => {
                                  if (result !== 'success')
                                    window.alert(`Error: ${result}`)
                                })
                            }}>Create new quotation sheet</ButtonTW>
                        </>
                      )}
                    </div>
                  </>
                )}

                <div className='col-all'>
                  <hr />
                  <h5>Sales information</h5>
                </div>
              </>
            )}

          </div>{/* </div class=maingrid> */}

          {action !== 'create' && (
            <>
              <div className='d-flex'>
                <SalesInfo2Crud
                  tourRequest={tourRequest}
                  userListSimple={userListSimple}
                  enableEditing={enableEditing}
                  autosaveNewStep={autosaveNewStep}
                />

                {showKintoneSalesInfo && tourRequest.salesInformation && (
                  <SalesInformationCrud
                    tourRequest={tourRequest}
                    userListSimple={userListSimple}
                  />
                )}

              </div>

              <div className='col-all' style={{ fontSize: 'small' }}>
                <CheckboxSwitch id='switchShowKintoneSalesInfo' label='Show Kintone sales information' checked={showKintoneSalesInfo} onChange={(e) => setShowKintoneSalesInfo(e.target.checked)} />
              </div>

              <hr />

              <div className='maingrid'>
                <div className='col1'></div>
                <div className='col2'>
                  {/* <ButtonTW onClick={(e) => saveTourRequestToDb()}>Save changes</ButtonTW> */}
                </div>

                <div className='col1'></div>
                <div className='col2'>
                  {statusMsg}
                </div>


                {action === 'edit' && userrole_isAdmin(userDetails.roles) && (
                  <>
                    <div className='col1 pt-2'>Admin tools</div>
                    <div className='col2'>
                      <ButtonTW variant='bsRed' textSize='md' onClick={(e) => {
                        if (!window.confirm('Are you sure you want to delete this request?'))
                          return

                        autosaveNewStep('DELETE', { _isDeleted: true }, 'UNDOWALL')
                          .then(() => {
                            console.log(`Deleted request ${tourrequestId}`)
                            log_db_write({ db, userDetails, logkey: 'db_write.tourrequest.delete', desc: `Deleted tour request [${tourRequest.requestCode}] [${tourrequestId}]` })
                            navigate('/requests/')
                          })
                          .catch((err) => setDbError(`Deleting request ${tourrequestId}`, err))

                      }}>Delete request</ButtonTW>

                    </div>
                  </>
                )}

                <div></div>
                <div></div>

              </div>
            </>
          )}
        </>


        <ModalTW
          title='Confirm adding Agency/Platform/Client'
          okLabel='OK'
          show={!!modalAction}
          callbackClose={() => setModalAction(null)}
          onSubmit={(e, onSuccess) => {
            e.preventDefault()

            if (!modalAction)
              throw new Error('modalAction is null')

            if (modalAction.action === 'add_agency') {

              if (!modalAction.newAgencyMnemonic) {
                window.alert('Please input a mnemonic')
                return
              }

              if (modalAction.newAgencyMnemonicError) {
                window.alert(`Error on mnemonic:\n${modalAction.newAgencyMnemonicError}`)
                return
              }

              const newAgencyName = modalAction.newAgencyName

              addNewAgency(db, userDetails, simpleAgencyList, newAgencyName, modalAction.newAgencyMnemonic)
                .then(({ newSimpleAgencyList, addedAgencySimple }) => {
                  console.log('tourRequest after assigning new agency', tourRequest, addedAgencySimple.name, addedAgencySimple.id, addedAgencySimple)

                  if (action === 'create') {
                    const newTourRequest = {
                      ...tourRequest,
                      agencyOrPlatform: addedAgencySimple.name,
                      agencyOrPlatformId: addedAgencySimple.id,
                    }
                    setTourRequest(newTourRequest)
                    resetAgency(newTourRequest)
                    onSuccess()
                  } else {
                    autosaveNewStep(`Change agency to ‘${addedAgencySimple.name}’ (newly created)`, {
                      agencyOrPlatform: addedAgencySimple.name,
                      agencyOrPlatformId: addedAgencySimple.id,
                    }, 'u')
                      .then(() => {
                        onSuccess()
                      })
                  }
                })
                .catch((err) => {
                  // we don't call setDbError as user would lose all their input?
                  window.alert(`Error in modalAction add_agency:\n${err}`)
                })

            }
          }}
          body={
            <div>
              <h6 className='tw-font-bold'>Agency name:</h6>
              <div>{modalAction && modalAction.newAgencyName}</div>

              <h6 className='tw-mt-5 tw-font-bold'>Mnemonic:</h6>
              <div>Please choose 2 characters that are not already used by another agency.</div>
              <div>
                <Form.Control type='text' value={(modalAction && modalAction.newAgencyMnemonic) || ''} onChange={(e) => {
                  trySetNewMnemonic(e.target.value)
                }} />
              </div>
              <div style={{ color: (modalAction?.newAgencyMnemonicError ? 'red' : 'green') }}>
                {modalAction && (modalAction.newAgencyMnemonicError ?? (modalAction.newAgencyMnemonic && 'OK'))}
              </div>

              <div className='tw-mt-4'>
                <div className='tw-mb-2'>Gray background indicates the 2 character code is already used by a different agency.</div>
                <table className='tw-text-xs tw-bg-white tw-cursor-default tw-border tw-border-solid tw-border-slate-300'>
                  <tbody>
                    {luhnCodePoints.split('').map((c1, iRow) => {
                      return (
                        <tr key={c1}>
                          {luhnCodePoints.split('').map((c2, iCol) => {
                            const code = `${c1}${c2}`
                            const agency = agencyHash.get(code)
                            return (
                              <td key={code}
                                className={`tw-text-center tw-cursor-pointer ${agency ? 'tw-bg-slate-300' : ''}`} title={agency?.name}
                                onClick={() => {
                                  trySetNewMnemonic(code)
                                }}
                              >
                                {code}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

            </div>
          } />



      </div>
    </div>
  )
}
