


export const userRolesMainList = ['dev', 'admin', 'payment_issuer', 'senior_inputter', 'inputter', 'role_guide', 'external_accountant', 'readonly', 'none'] as const
export type UserRoleMainType = typeof userRolesMainList[number]


export const userRolesOtherList = ['role_expense_accounting', 'role_calendar_editor'] as const
export type UserRoleOthersEnumType = typeof userRolesOtherList[number]
export type UserRoleOthersType = {
  [key in UserRoleOthersEnumType]?: boolean;
}
//export type UserRoleOthersEnumType = keyof UserRoleOthersType



export type UserRolesType = {
  mainRole: UserRoleMainType;
  roleFlags: UserRoleOthersType;
}


export const permissionListAll = [
  'invoices_read',
  'invoices_freee',
  'invoices_create',
  'invoices_write',
  'guiding_expenses_view_own', // previously: expenses_guide    // view (and edit) user's own guiding expense sheets -> guides
  'guiding_expenses_view_all', // previously: expenses_designer // view all users' guiding expense sheets (edit rights based on user list on document) -> office workers
  'gen_expenses_view_own', // view (and edit) own -> guides
  'gen_expenses_view_all', // view all (edit based on each doc settings) -> office workers, external accountant
  'tourrequests_read',
  'commute_page',
  'payments_create',
  'users_read',
  'permissions_write',
  'view_all_expense_accounting',
  'view_tour_calendar',
  'edit_tour_calendar',
  'view_notion',
  'commute_edit_all',
] as const
export type UserPermissionAllEnumType = typeof permissionListAll[number]

// export const permissionListOthers = [
// ] as const
// export type UserPermissionOthersEnumType = typeof permissionListOthers[number]







export function displayRole(role: UserRoleMainType | UserRoleOthersEnumType) {
  if (!role)
    return 'None'

  let roleStr: string = role
  if (roleStr.startsWith('role_'))
    roleStr = roleStr.slice(5)

  return roleStr.charAt(0).toUpperCase() + roleStr.slice(1).replaceAll('_', ' ')
}

export function getRoleScore(role: UserRoleMainType) {
  if (role === 'dev') return 1000
  if (role === 'admin') return 100
  if (role === 'payment_issuer') return 10
  if (role === 'senior_inputter') return 7
  if (role === 'inputter') return 5
  if (role === 'external_accountant') return 2
  if (role === 'readonly') return 1
  return 0
}

const YYY = 'Y' as const

// all employees, including guides
const role_employee = {
  users_read: YYY,
  view_notion: YYY,
  commute_page: YYY,
  gen_expenses_view_own: YYY,
  guiding_expenses_view_own: YYY,
  view_tour_calendar: YYY,
}

// all employees except guides
const role_officeworker = {
  ...role_employee,
  gen_expenses_view_all: YYY,
  guiding_expenses_view_all: YYY,
  invoices_read: YYY,
  invoices_create: YYY,
  tourrequests_read: YYY,
}


// the following roles are mutually exclusive. the key is stored in users.role
const permissionGrid_main_role:
  { dev: Record<UserPermissionAllEnumType, 'Y'> } // <- this is to make sure we don't forget any permissions in the below grid
  & Record<UserRoleMainType, Partial<Record<UserPermissionAllEnumType, 'Y'>>> = {
  dev:                 /**/ { ...role_officeworker, invoices_write: 'Y', invoices_freee: 'Y', payments_create: 'Y', view_all_expense_accounting: 'Y', commute_edit_all: 'Y', permissions_write: 'Y', edit_tour_calendar: 'Y', },
  admin:               /**/ { ...role_officeworker, invoices_write: 'Y', invoices_freee: 'Y', payments_create: 'Y', view_all_expense_accounting: 'Y', commute_edit_all: 'Y', permissions_write: 'Y', edit_tour_calendar: 'Y', },
  payment_issuer:      /**/ { ...role_officeworker, invoices_write: 'Y', invoices_freee: 'Y', payments_create: 'Y', view_all_expense_accounting: 'Y', commute_edit_all: 'Y', },
  senior_inputter:     /**/ { ...role_officeworker, invoices_write: 'Y', },
  inputter:            /**/ { ...role_officeworker, },
  role_guide:          /**/ { ...role_employee, },
  external_accountant: /**/ { users_read: 'Y', gen_expenses_view_own: 'Y', gen_expenses_view_all: 'Y', guiding_expenses_view_own: 'Y', guiding_expenses_view_all: 'Y', invoices_read: 'Y', invoices_freee: 'Y', view_all_expense_accounting: 'Y', },
  readonly:            /**/ { users_read: 'Y', invoices_read: 'Y', },
  none:                /**/ {},
}

// the following roles are not mutually exclusive, and can each be enabled by a flag in users.role_others.
const permissionGrid_role_others = {
  role_expense_accounting: /**/ { view_all_expense_accounting: 'Y', },
  role_calendar_editor:    /**/ { edit_tour_calendar: 'Y', },
}


// how the roles and permissions are stored in db:
// * permissions in permissions collection MUST be stored explicitly, with false value for non-granted permission,
//   because if Firestore rules check permSnap.data.invoices_read == true when that field is absent, it will raise an
//   error 'Property invoices_read is undefined on object'
// * roles in user.role_others are also stored explicitly, with false value for non-granted role
//   (we also could potentially consider deleting the field for non-grated roles)



export function getPermissionObjectFromRoles(dateModified: any, roles: UserRolesType) {
  // generates the permissions object automatically, based on the user's assigned roles
  const permObj: Record<string, any> = {
    dateModified,
  }
  for (const perm of permissionListAll) {
    permObj[perm] = getPermission_from_roles(perm, roles)
  }
  return permObj as Record<UserPermissionAllEnumType, boolean> & { 'dateModified': any }
}

export function roleOthersArrayToObj(role_others_array: UserRoleOthersEnumType[]): UserRoleOthersType {
  // convert from an array to an object.
  // e.g. ['role_calendar_editor'] => { role_calendar_editor: true, role_expense_accounting: false }
  // the object can then be saved on user object as user.role_others
  const role_others_obj: UserRoleOthersType = {}
  for (const role_other of userRolesOtherList) {
    role_others_obj[role_other] = role_others_array.includes(role_other)
  }
  return role_others_obj
}



export function getPermission_from_roles(permissionName: UserPermissionAllEnumType, roles: UserRolesType | undefined) {
  if (!roles) {
    // during app initialization, before userDetails are loaded, in HeaderNavBar
    return false
  }

  const permissionRow: Record<string, string> = permissionGrid_main_role[roles.mainRole]
  if (!permissionRow) {
    throw new Error(`invalid role: ${roles.mainRole}`)
  }

  const permissionValue = permissionRow[permissionName]
  const permitted = permissionValue === 'Y'
  if (permitted) {
    return true
  }

  if (roles.roleFlags) {
    for (const [key, value] of Object.entries(roles.roleFlags)) {
      if (!value) {
        continue
      }
      const permissionRow: Record<string, string> = permissionGrid_role_others[key as UserRoleOthersEnumType]
      const permissionValue = permissionRow[permissionName]
      const permitted = permissionValue === 'Y'
      if (permitted) {
        return true
      }
    }
  }

  return false
}


export function userrole_canViewCalendar(roles: UserRolesType) {
  return getPermission_from_roles('view_tour_calendar', roles)
}

export function userrole_canListInvoices(roles: UserRolesType) {
  return getPermission_from_roles('invoices_read', roles)
}

export function userrole_canViewRequests(roles: UserRolesType) {
  return userrole_canListInvoices(roles)
}

export function userrole_canEditFreee(roles: UserRolesType) {
  return getPermission_from_roles('invoices_freee', roles)
}

export function userrole_canAddInvoice(roles: UserRolesType) {
  return getPermission_from_roles('invoices_create', roles)
}

export function userrole_canEditCalendar(roles: UserRolesType) {
  return getPermission_from_roles('edit_tour_calendar', roles)
}

export function userrole_canListPayees(roles: UserRolesType) {
  // we don't allow readonly users to list payees
  // TODO: separate this permission
  return userrole_canAddInvoice(roles)
}

export function userrole_canEditAnyInvoice(roles: UserRolesType) {
  return getPermission_from_roles('invoices_write', roles)
}

export function userrole_canDeleteInvoices(roles: UserRolesType) {
  // payments_issuer or above.
  // TODO: separate this permission
  return roles?.mainRole === 'payment_issuer' || roles?.mainRole === 'admin' || roles?.mainRole === 'dev'
}

export function userrole_canMarkPaid(roles: UserRolesType) {
  return getPermission_from_roles('payments_create', roles)
}

export function userrole_isAdmin(roles: UserRolesType) {
  return roles?.mainRole === 'admin' || roles?.mainRole === 'dev'
}

export function userrole_isDev(roles: UserRolesType) {
  return roles?.mainRole === 'dev'
}
